<template>
    <div>
      <!-- Confirmation Modal -->
      <b-modal id="dynamic-confirmation-modal" centered hide-footer scrollable size="xl" :title=msg_title>
        <div class="d-block text-center">
          <p v-html="msg"></p> 
          <b-button variant="success" class="m-2" @click="confirm">Yes</b-button>
          <b-button variant="danger" class="m-2" @click="cancel">No</b-button>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        msg:{
            type:String
        },
        msg_title:{
            type:String
        }
    },
    methods: {
      confirm() {
        // Handle the confirmation action
        this.$emit("confirmed");
      },
      cancel() {
        // Handle the cancellation action
        this.$bvModal.hide('dynamic-confirmation-modal');

      }
    }
  }
  </script>
  

  <style scoped>
/* Center the modal content and style buttons */
.d-block.text-center {
  padding: 20px;
}

.b-button {
  margin: 0 10px;
}

.b-modal-content {
  background: linear-gradient(135deg, var(--primary-color) 0%, #6f42c1 100%);
  color: white;
}

.b-modal-header {
  background: var(--primary-color);
  color: white;
  border-bottom: none;
}

.b-modal-footer {
  border-top: none;
}
</style>
