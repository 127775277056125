<template>
  <div class="problem-container">
    <div class="problem-container-header">Description</div>
    <div class="problem-container-body" v-if="problemData">
      <div class="problem-title">{{ problemData.Title }}</div>
      <div class="problem-tagSection">
        <div
          class="problem-tag"
          v-for="i in JSON.parse(problemData.Tags)"
          :key="i"
        >
          {{ i }}
        </div>
      </div>
      <div class="problem-description">
        {{ problemData.Description }}
        
      </div>
      <div class="mt-4 ">
        <div class="text-bold">Examples : - </div>
        <div
        class="problem-examples-container p-2"
        v-for="data in problemData.TestCaseData"
        :key="data.TestCaseId"
      >
        <div class="text-bold mb-3">{{ data.TestCaseName }}</div>
        <div class="d-flex ml-2">
          <div class="text-bold">Inputs:</div>
          <div
            class="d-flex ml-2"
            v-for="(item, i) in JSON.parse(data.Inputs)"
            :key="i"
          >
            <div>{{ item.label }} = {{ item.value }}</div>
            <div v-if="i != JSON.parse(data.Inputs).length - 1">,</div>
          </div>
        </div>
        <div class="d-flex ml-2">
          <div class="text-bold">Output:</div>
          <div class="ml-2">{{ data.ExpectedOutput }}</div>
        </div>
      </div>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return this.initialState();
  },
  watch: {
    data(newValue) {
      console.log("desc-->",newValue);
      this.problemData = newValue;
    },
  },
  mounted() {
    console.log("initialState-->",this.problemData);
  },
  methods: {
    initialState() {
      return {
        problemData: this.data ||"",
      };
    },
  },
};
</script>

<style src="./Descriptionlayout.css"></style>
