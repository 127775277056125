<template>
  <div>
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div>
      <div class="header header-layout-2">
        <div class="header-layout-2-logoSection">
          <img class="header-logoIcon" src="../assets/logoIcon.png" />
          <img class="header-logoImg" src="../assets/loginPageLogo.png" />
        </div>
        <div></div>
        <div v-if="showSubmit" class="ml-auto">
          <button  class="submitButton finishButton p-2" @click="submitCode">
            Submit
          </button>
        </div>
      </div>
      <div class="body bg-primary-gradient-1">
        <div class="problems-layout">
          <descriptionlayout :data="problemData" />
          <code-section-layout
            :data="problemData"
            @language-change="updateLanguage"
            @code-change="updateCode"
          />
        </div>
      </div>
    </div>

    <b-modal id="submitted-info-modal" ref="error-modal" size= 'sm' no-close-on-backdrop hide-header-close  buttonSize= 'sm'  headerClass='p-2 border-bottom-0'  footerClass='p-2 border-top-0'  @ok="goBack" ok-only centered title="Information">
    <p class="my-4">You have submitted this problem.</p>
  </b-modal>
  </div>
</template>

<script>
import { getCandidateProblemDetails, submitCodeProblem } from "../api/index";
import CodeSectionLayout from "../components/CodeProblems/CodeSectionLayout/CodeSectionLayout.vue";
import Descriptionlayout from "../components/CodeProblems/Description/Descriptionlayout.vue";
export default {
  components: { Descriptionlayout, CodeSectionLayout },
  data() {
    return this.initialState();
  },
  mounted() {
    this.getProblemData();
  },
  computed: {
    showSubmit(){
      return (this.problemData)?this.problemData.candidateProblemDetails?.Status !="Submitted":false
    }
  },
  methods: {
    initialState() {
      return {
        loading: false,
        loadingMsg: "Please wait...",
        code: "",
        language: "js",
        errMsg: "",
        problemData: "",
      };
    },
    getProblemData() {
      this.loading = true;
      let payload = {
        problem_Id: this.$route.params.Id,
        InterviewId: this.$store.state.InterviewId,
        CandidateId: this.$store.state.candidateInfo.Id,
      };
      getCandidateProblemDetails(payload)
        .then((resp) => {
          this.problemData = resp.data.data;
          this.loading = false;
          if(this.problemData?.candidateProblemDetails?.Status =="Submitted"){
            this.$bvModal.show('submitted-info-modal');
          }
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    submitCode() {
      this.loading = true;
      const payload = {
        code: this.code,
        language: this.language,
        problemId: this.$route.params.Id,
        InterviewId: this.$store.state.InterviewId,
        CandidateId: this.$store.state.candidateInfo.Id,
        CandidateProblemResId: this.$store.state.CandidateProblemResId,
      };
      submitCodeProblem(payload)
        .then((resp) => {
          console.log(resp);
          this.goBack();
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.errMsg = err.response.data;
          this.loading = false;
        });
    },
    updateLanguage(language) {
      this.language = language;
    },
    updateCode(code) {
      this.code = code;
    },
  },
};
</script>

<style>
.problems-layout {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 12px;
}
.problems-codeSection-layout {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  gap: 8px;
}
.bg-primary-gradient-1 {
  background: linear-gradient(145deg, #390879, #240551); /* Theme gradient */
}
</style>
