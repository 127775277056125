<template>
  <div class="problems-codeSection-layout">
    <code-view :data="problemData" @run="runCode" :loading="loading" 
    @language-change="updateLanguage"
    @code-change="updateCode"/>
    <test-result-view :output="output" :loading="loading"/>
  </div>
</template>

<script>
import { codeCompileanRrun } from "../../../api/index";
import CodeView from "../../../components/CodeProblems/Code/CodeView.vue";
import TestResultView from "../../../components/CodeProblems/TestResult/TestResultView.vue";
export default {
  props: {
    data: Object,
  },
  components: { CodeView, TestResultView },
  data() {
    return this.initialState();
  },
  watch: {
    data(newValue) {
      this.problemData = newValue;
    },
  },
  mounted() {},
  methods: {
    initialState() {
      return {
        loading: false,
        problemData: {},
        output:""
      };
    },
    runCode(payload) {
    this.loading=true;
    this.output="";
      codeCompileanRrun(payload)
        .then((resp) => {
          const obj={
            data:resp.data.output,
            syntaxError:false
          }
          this.output = obj;
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          const obj={
            data:err.response ? err.response.data.error : 'An error occurred',
            syntaxError:true
          }
          this.output = obj;
          this.loading = false;
        });
    },
    updateLanguage(language) {
      this.$emit('language-change', language);
    },
    updateCode(code) {
      this.$emit('code-change', code);
    },
  },
};
</script>

<style>
.problems-codeSection-layout {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  gap: 8px;
}
</style>
