<template>
  <div>
    <b-modal
      id="codeProblemDetails-modal"
      class="modal_body-lg"
      centered
      title="Problem Description"
      scrollable
      no-close-on-backdrop
      @close="cancel"
      hide-footer
      size="xl"
    >
      <template #modal-title>
        <div class="">
          <div>Problem Overview</div>
        </div>
      </template>
      <div class="modal_body-lg">
        <div v-if="loading" class="d-flex justify-content-center">
          <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
        </div>
          <descriptionlayout v-show="!loading" :data="problemData"/>

      </div>
    </b-modal>
  </div>
</template>

<script>
import { getProblemDetails } from "../../api/index";
import Descriptionlayout from '../CodeProblems/Description/Descriptionlayout.vue';
export default {
  name: "codeProblemDetailsModal",
  props: {
    problem_Id: Number,
    showProblemDescription: Boolean,
  },
  components: {Descriptionlayout},
  data() {
    return {
      loading: false,
      loadingMsg: "Please wait...",
      problemData: "",
    };
  },
  watch: {
    showProblemDescription(newVal) {
      if (newVal) {
        this.$bvModal.show("codeProblemDetails-modal");
        this.getProblemData();
      }
    },
  },
  computed: {},
  mounted() {},
  methods: {
    getProblemData() {
      this.loading = true;
      let payload = {
        problem_Id: this.problem_Id,
        testcase_Display_Flg: "",
      };
      getProblemDetails(payload)
        .then((resp) => {
          this.problemData = resp.data.data;
          console.log("problemData",this.problemData)
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    cancel(){
      this.$emit('handleClose');
      this.$bvModal.hide("codeProblemDetails-modal");
    }
  },
};
</script>

<style >

</style>
