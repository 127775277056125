<template>
  <div class="problem-container">
    <div class="problem-container-header">
      Code
      <div v-if="code && showRun" class="run-code-btn" @click="runCode" >Run</div>
    </div>
    <div class="problem-container-body">
      <div class="code-section">
        <div class="px-2">
          <select v-model="language" @change="handleLanguageChange" id="language" class="border-0 text-bold">
            <option value="js">JavaScript</option>
            <option value="python">Python</option>
            <option value="java">Java</option>
            <option value="c">C</option>
            <option value="cpp">C++</option>
          </select>
        </div>
        <div class="code-input-container">
          <b-form-textarea
            class="code-input"
            id="textarea"
            v-model="code"
            @input="emitCodeChange"
          ></b-form-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    loading: Boolean,
  },
  data() {
    return this.initialState();
  },
  
  watch: {
    data(newValue) {
      this.problemData = newValue;
      this.code = JSON.parse(newValue.CodeSnippets)[this.language];
    },
  },
  computed: {
    showRun(){
      return (this.problemData)?this.problemData.candidateProblemDetails?.Status !="Submitted":false
    }
  },
  methods: {
    initialState() {
      return {
        language: "js",
        code: "",
        problemData:""
      };
    },
    handleLanguageChange(){
      this.code = JSON.parse(this.problemData.CodeSnippets)[this.language];
      this.emitLanguageChange();
    },
    emitLanguageChange() {
      this.$emit('language-change', this.language);
    },
    emitCodeChange() {
      this.$emit('code-change', this.code);
    },
    runCode() {
      if(!this.loading){
      let payload = {
        code:this.code,
        language:this.language,
        problemId:this.problemData.ProblemId
      };
      this.$emit("run",payload);
      }
    },
  },
};
</script>

<style src="./CodeView.css"></style>
