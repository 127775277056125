<template>
  <div class="table-container">
    <div class="codeProblem-list-header">
      <div class="header-title pl-2 pr-4">Title</div>
      <div class="header-difficulty">Created By</div>
      <div class="header-difficulty">Difficulty</div>
      <div v-if="showAddBtn" class="mr-2">Action</div>
    </div>
    <div v-if="loading" class="d-flex justify-content-center">
              <b-icon
                icon="three-dots"
                animation="cylon"
                font-scale="4"
              ></b-icon>
            </div>
    <div class="codeProblem-list">
      <div
        class="codeProblem-item-row"
        v-for="(item, index) in codeProblemsData"
        :key="index"
        
      >
        <div class="item-title pl-2 pr-4" :title="item.Title" @click="handleProblemClick(item)">
          {{ item.Title }}
        </div>
        <div
         @click="handleProblemClick(item)"
          class="item-difficulty  text-md"
        >
          {{ item.probCreatedBy }}
        </div>
        <div
         @click="handleProblemClick(item)"
          class="item-difficulty text-bold text-md"
          :class="{
            'difficulty-easy': item.Difficulty === 'Easy',
            'difficulty-medium': item.Difficulty === 'Medium',
            'difficulty-hard': item.Difficulty === 'Hard',
          }"
        >
          {{ item.Difficulty }}
        </div>
        <div
          v-if="showAddBtn"
          class=""
        >
        <button class="submitButton finishButton px-2 py-1 mr-2" @click="handleAddProblem(item)">
            Add
          </button>
        </div>
      </div>
    </div>
    <code-problem-details
      :problem_Id="selectedProblemId"
      :showProblemDescription="showProblemDescriptionModal"
      @handleClose="showProblemDescriptionModal = false"
    />
  </div>
</template>

<script>
import { getCodeProblems } from "../../api/index";
import CodeProblemDetails from "../Modal/codeProblemDetails.vue";
export default {
  components: { CodeProblemDetails },
  props: {
    showAddBtn: {
      type: Boolean,
      default: false,
    },
    refreshProbelmsList: {
      type: Function
    },
  },
  watch: {
    refreshProbelmsList: {
      handler() {
        this.getCodeProblemData();
      },
      immediate: false, // Set to false if you want to avoid calling on initial mount
   }
  },
  data() {
    return this.initialState();
  },
  mounted() {
    this.getCodeProblemData();
  },
  methods: {
    initialState() {
      return {
        loading: false,
        problemData: {},
        codeProblemsData: [],
        selectedProblemId: 0,
        showProblemDescriptionModal: false,
      };
    },
    handleProblemClick(item) {
      this.selectedProblemId = item.ProblemId;
      this.showProblemDescriptionModal = true;
    },
    getCodeProblemData() {
      this.loading = true;
      getCodeProblems()
        .then((resp) => {
          this.codeProblemsData = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    handleAddProblem(item){
        this.$emit("handleAdd",item);
    }
  },
};
</script>

<style></style>
