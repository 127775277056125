<template>
  <div class="Home">
    <!--This Header needs changed completely  -->
    <div
      class="header-layout-1 home-header navlink-layout-1 bg-wt"
    >
      <div
        class="header-layout-1-logoSection home-logo-header"
        @click="$router.push('/')"
      >
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img
 
          class="header-logoIcon"
          src="../assets/loginPageLogo.png"
        />
        <!-- <img v-else class="header-logoImg" src="../assets/loginPageLogo.png" /> -->
      </div>

      <div class="header-layout-1-navLinks">
        <div class="nav-link" @click="$router.push('/')">
          Home
        </div>
        <div class="nav-link" @click="$router.push('/tm-interview')">TM Interviews</div>
      <div class="nav-link" @click="$router.push('/tm-engagement')">TM Engage</div>
      <div class="nav-link" @click="$router.push('/about')">About</div>
      <div class="nav-link" @click="$router.push('/contact')">Contact</div>
    </div>
      <div class="header-layout-1-rightHeader home-right-header">
        <div class="home-login-div">
          <button class="home-login-btn" @click="$router.push('/login')">
            Login
          </button>
        </div>
      </div>

        <div class="home-nav-toggle" @click="toggleNavMenu">
          <b-icon icon="list" class="rounded p-1"></b-icon>
        </div>
        <div class="mobile-nav-links" v-if="isNavMenuVisible">
          <div class="mobile-nav-link" @click="$router.push('/')">
          Home
        </div>
        <div class="mobile-nav-link" @click="$router.push('/tm-interview')">
          TM Interviews
        </div>
        <div class="mobile-nav-link" @click="$router.push('/tm-engagement')">
          TM Engagement
        </div>
        <div class="mobile-nav-link" @click="$router.push('/about')">About</div>
        <div class="mobile-nav-link" @click="$router.push('/contact')">
          Contact
        </div>
      </div>
    </div>

    <!-- page starts -->

    <div class="home-body">

      <div class="hero-container-1 h-50vh hero-bg-1">
        <div class="section-title text-center">
           About us
          </div>

        <div class="hero-subtitle  w-full quote-text">
          The ultimate platform for platform that seamlessly integrates both team-building and technical assessment capabilities to enhance productivity and hiring processes.

       </div>
      </div>

      <div class="hero-container hero-bg-1">
        <div class="details-section">
          <div class="section-title">
            Our Mission
          </div>
          <div class="home-li-item text-justify">
            At TeamsMagic, our mission is to create innovative virtual experiences that foster team collaboration, strengthen communication, and boost productivity, no matter where teams are located. We are committed to driving organizational success and supporting employee growth through a seamless, customizable platform for team-building activities, corporate events, and technical assessments.

          </div>

        </div>
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/tm_works.png" />
        </div>
      </div>
      <div class="home-footer">
        <div class="home-footer-body">
          <div class="home-footer-logo-div">
            <img class="footer-tm-log" src="../assets/logoWhite.png" />
          </div>
          <div class="home-footer-about">
            <div>A virtual platform provides employee engagement with technical assessment capabilities for your teams, worldwide.</div>
            <div>Follow us on 
              <b-icon icon="linkedin"  @click="openLinkedIn"></b-icon>
            </div>
            
          </div>
        </div>
        <div class="copywirte-div">
          © {{ currentYear }} Icosphere Technologies. All Rights Reserved. |
          <div class="privacy-tablink" @click="$router.push('/privacy')">
            Privacy
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "About",
    meta: [
      {
        name: "description",
        content:
          "Our platform offers a wide range of customizable team building activities, along with powerful features like room creation and admin control.",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
  },
  data() {
    return {
      currentYear: "",
      isNavMenuVisible: false
    }
  },
  mounted() {
    const d = new Date();
    this.currentYear = d.getFullYear();
  },
  methods:{
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/teamsmagic/', '_blank');
    },
    toggleNavMenu() {
      this.isNavMenuVisible = !this.isNavMenuVisible;
    },
  }
}
</script>

<style>
.h-70vh{
  height: 70vh;
}
.h-50vh{
  height: 50vh;
}
.quote-text{
  font-weight: 800;
  font-style: italic;
}
</style>
