<template>
  <div class="table-container">
    <div class="codeProblem-list-header">
      <div class="flex-3">Name</div>
      <div class="flex-3">Email</div>
      <div class="flex-3">Status</div>
      <div class="flex-3">Score</div>
    </div>
    <div class="codeProblem-list">
      <div
        v-for="(item, index) in interviewResultsData"
        :key="index"
        @click="handleResultClick(item)"
      >
        <div  class="codeProblem-item-row">
          <div class="flex-3" :title="item.FullName">
            {{ item.FullName }}
          </div>
          <div class="flex-3">{{ item.Email }}</div>
          <div class="flex-3">{{ item.Status }}</div>
          <div
            class="flex-3 text-bold text-md"
            :class="{
              'difficulty-easy': item.AverageScore >= 70,
              'difficulty-medium':
                item.AverageScore >= 45 && item.AverageScore < 70,
              'difficulty-hard': item.AverageScore < 45,
            }"
          >
            {{ item.AverageScore }}
          </div>
        </div>

          <!-- problems Results -->
      <b-collapse id="collapse-4" v-if="selectedResultsData.CandidateId == item.CandidateId" v-model="showDetailedResult" class="m-3  background-light-gray">
        <div
          class="codeProblem-item-row border-0"
          v-for="(item, index) in selectedResultsData.Problems"
          :key="index"
        >
          <div class="flex-3" :title="item.Title">
            {{ item.Title }}
          </div>
          <div class="flex-3">{{ item.Difficulty }}</div>
          <div class="flex-3">{{ item.Status }}</div>
          <div
            class="flex-3 text-bold text-md"
            :class="{
              'difficulty-easy': item.Score >= 70,
              'difficulty-medium': item.Score >= 45 && item.Score < 70,
              'difficulty-hard': item.Score < 45,
            }"
          >
            {{ item.Score }}
          </div>
        </div>
      </b-collapse>
      </div>
    
    </div>
  </div>
</template>

<script>
import { getInterviewResults } from "../../api/index";
export default {
  components: {},
  props: {},
  data() {
    return this.initialState();
  },
  mounted() {
    this.getResults();
  },
  methods: {
    initialState() {
      return {
        loading: false,
        interviewResultsData: [],
        selectedResultsData: {},
        showDetailedResult: false,
      };
    },
    getResults() {
      this.loading = true;
      let payload = {
        Id: this.$route.params.Id,
      };
      getInterviewResults(payload)
        .then((resp) => {
          this.interviewResultsData = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    handleResultClick(item) {
      if (
        !this.showDetailedResult ||
        this.selectedResultsData.CandidateId === item.CandidateId
      ) {
        this.showDetailedResult = !this.showDetailedResult;
      }
      this.selectedResultsData = item;
    },
  },
};
</script>

<style></style>
