<template>
  <div class="page-layout">
    <div class="w-full">
      <div class="my-2">
        <div class="d-flex justify-content-end">
          <button class="submitButton finishButton" @click="showCreateProblem">
            Create Problem
          </button>
          <button class="submitButton addButton" @click="showCreateInterview" >Create Interview</button>
        </div>
      </div>
      <div class="my-2">
        <b-tabs v-model="activeTab">
            <b-tab title="Interviews" lazy :title-link-class="'m-0'"><interview-table :refreshInterview ="infiniteInterview"/></b-tab>
            <b-tab title="Owned Problems" lazy :title-link-class="'m-0'"> <owned-problems-table :showAddBtn="false" :refreshProbelmsList="infiniteProblems"/> </b-tab>
            <b-tab title="Library" lazy :title-link-class="'m-0'"> <problems-table :showAddBtn="false" :refreshProbelmsList="infiniteProblems"/></b-tab>
          </b-tabs>
      </div>
    </div>

    <createProblem @refreshProbelmsList="refreshProbelmsList" />
    <create-interview-modal @refreshInterviews="refreshInterviews"/>
  </div>
</template>

<script>
import CreateInterviewModal from '../components/Modal/createInterviewModal.vue';
import createProblem from "../components/Modal/createProblem.vue";
import InterviewTable from '../components/TmInterview/InterviewTable.vue';
import OwnedProblemsTable from '../components/TmInterview/OwnedProblemsTable.vue';
import ProblemsTable from '../components/TmInterview/problemsTable.vue';
export default {
  components: { createProblem, CreateInterviewModal, ProblemsTable, InterviewTable, OwnedProblemsTable },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        activeTab:0,
        loading: false,
        problemData: {},
        selectedProblemId: 0,
        showProblemDescriptionModal: false,
        infiniteInterview:0,
        infiniteProblems:0,
      };
    },
    showCreateProblem() {
      this.$bvModal.show("createProblem-modal");
    },
    showCreateInterview() {
      this.$bvModal.show("createInterview-modal");
    },
    refreshInterviews() {
      if(this.activeTab==0){
        this.infiniteInterview+=1;
      }
    },
    refreshProbelmsList() {
      if(this.activeTab==1){
        this.infiniteProblems+=1;
      }
    },
  },
};
</script>

<style>
.page-layout {
  width: 100%;
  height: 89vh;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.table-container {
  width: 100%;
  max-height: 90%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.codeProblem-list-header {
  display: flex;
  background-color: var(--primary-color);
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.header-title,
.header-difficulty {
  flex: 1;
}

.codeProblem-list {
  border-top: 1px solid #e0e0e0;
}

.codeProblem-item-row {
  align-items: center;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.codeProblem-item-row:hover {
  background-color: #f1f1f1;
}

.item-title,
.item-difficulty {
  flex: 1;
}

.item-title {
  cursor: pointer;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-difficulty {
  cursor: pointer;
  color: #666;
}

.header-title,
.item-title {
  flex: 7;
}

.header-difficulty,
.flex-3,
.item-difficulty {
  flex: 3;
}
.difficulty-easy {
  color: green;
}

.difficulty-medium {
  color: orange;
}

.difficulty-hard {
  color: red;
}

.btn-md1 {
  cursor: pointer;
  padding: 2px;
  padding-left: 16px;
  padding-right: 16px;
  background: linear-gradient(145deg, #4f0c9c, #390879);
  color: #fff;
  border-radius: var(--border-radius-md);
}
.bg-color-1 {
  background: linear-gradient(145deg, #4f0c9c, #390879);
}
</style>
