<template>
  <div v-if="interviewData.length>0" class="pb-4">
    <div v-if="loading" class="d-flex justify-content-center">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
      </div>
    <div class="SectionTitle">Ongoing Interviews</div>
    <div class="table-container">
      
      <div class="codeProblem-list">
        <div
          class="codeProblem-item-row"
          v-for="(item, index) in interviewData"
          :key="index"
          @click="handleInterviewClick(item)"
        >
          <div class="item-title pl-2 pr-4" :title="item.Title">
            {{ item.Title }}
          </div>
          <div class="flex-3">
            {{ item.Position }}
          </div>
          <div
            class="flex-3 text-bold"
            :class="{
              'difficulty-easy': item.Active_Flag == 'Y',
              'difficulty-hard': item.Active_Flag != 'Y',
            }"
          >
            {{ item.Active_Flag == "Y" ? "Active" : "Inactive" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllInterviews } from "../../api/index";
export default {
  name: "homeInterview-section",
  components: {},
  props: {
    refreshInterview: {
      type: Function,
    },
  },
  watch: {
    refreshInterview: {
      handler() {
        this.getInterviewListData();
      },
      immediate: false, // Set to false if you want to avoid calling on initial mount
    },
  },
  data() {
    return this.initialState();
  },
  mounted() {
    this.getInterviewListData();
  },
  methods: {
    initialState() {
      return {
        loading: false,
        problemData: {},
        interviewData: [],
        selectedProblemId: 0,
      };
    },
    handleInterviewClick(item) {
      this.$router.push(`/interview/${item.Id}`);
    },
    getInterviewListData() {
      this.loading = true;
      const payload = {
        Active_Flag: "Y",
      };
      getAllInterviews(payload)
        .then((resp) => {
          this.interviewData = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
