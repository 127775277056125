export const tabs = [
  {
    name: "Home",
    route: "/homepage",
    icon: "fas fa-home",
    subtitle: "Welcome",
    display_Flg: "Y",
  },
  {
    name: "Marketplace",
    route: "/marketplace",
    icon: "fas fa-store",
    subtitle: "Best Content Available",
    display_Flg: "Y",
  },
  {
    name: "Meditation",
    route: "/meditation",
    icon: "fas fa-spa",
    subtitle: "",
    display_Flg: "Y",
  },
  {
    name: "Create Activity",
    route: "/create-activity",
    icon: "fas fa-magic",
    subtitle: "Plan Your New Event",
    display_Flg: "Y",
  },
  {
    name: "Activity Formats",
    route: "/gamesdashboard",
    icon: "fas fa-layer-group",
    subtitle: "",
    display_Flg: "Y",
  },
  {
    name: "Rewards",
    route: "/rewards",
    icon: "fas fa-trophy",
    subtitle: "Earn Points & Win",
    display_Flg: "Y",
    disabled: true,
  },
  {
    name: "Live Events",
    route: "/live-events",
    icon: "fas fa-calendar-alt",
    subtitle: "Upcoming Events",
    display_Flg: "Y",
    disabled: true,
  },
  {
    name: "TM Interviews",
    route: "/interviews",
    icon: "fas fa-clipboard-list",
    subtitle: "Plan Your Interview",
    display_Flg: "Y",
    disabled: false,
  },
  {
    name: "Activity Information",
    route: "/activity-info",
    icon: "",
    subtitle: "",
    display_Flg: "N",
  },
];
