export const guidelineTitle = `Important Notice: Test Monitoring Guidelines`
export const guidelines = 
`
Attention, Candidate!<br><br>

Before you begin the test, please carefully review the following guidelines:<br><br>

<strong>Monitoring in Progress</strong><br>
Once you start the test, your activity will be monitored continuously to ensure a fair testing environment.<br><br>

<strong>Malpractice Detection</strong><br>
Any attempts at malpractice, including but not limited to:<br>
- Switching tabs<br>
- Opening new tabs<br>
- Minimizing the test window<br>
Will result in automatic submission of your test.<br><br>

<strong>Test Submission</strong><br>
If any of the above actions are detected, your test will be submitted without warning, and you may not have the opportunity to complete it.<br><br>

<strong>Confirmation Required</strong><br>
Are you sure you want to start the test? Please ensure you are in a distraction-free environment and ready to commit your full attention.
`;
