<template>
  <div class="problem-container">
    <div class="problem-container-header">Test Result</div>
    <div class="problem-container-body">
      <b-icon
        v-if="loading"
        icon="three-dots"
        animation="cylon"
      ></b-icon>
      <div v-if="!loading" style="height: 100%;">
        <div v-if="!outputData" class="default-testresult-placeholder">
          You must run your code first
        </div>
        <div v-else class="testResults-container">
          <div v-if="outputData.syntaxError">{{ outputData.data }}</div>
          <div v-else>
            <div class="test-tabs">
              <div
                v-for="(item, i) in outputData.data"
                :key="i"
                class="testcase-tab d-flex pr-2"
                :class="
                  activeTestCaseTabIndex == i ? 'testcase-tab-active' : ''
                "
                @click="handleTabClick(i)"
              >
                <div class="pr-2">
                  <b-icon
                    v-if="item.isPassed"
                    icon="check-circle-fill"
                    variant="success"
                  ></b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                </div>
                {{ item.name }}
              </div>
            </div>
            <div
              class="py-2"
              v-for="(item, i) in activeTestCaseTab.inputs"
              :key="i"
            >
              <div class="text-bold px-2">{{ item.label }} =</div>
              <div class="testResult-input py-2">{{ item.value }}</div>
            </div>
            <div class="py-2">
              <div class="text-bold px-2">Output</div>
              <div class="testResult-input py-2">
                {{ activeTestCaseTab.output }}
              </div>
            </div>
            <div class="py-2">
              <div class="text-bold px-2">Expected Output</div>
              <div class="testResult-input py-2">
                {{ activeTestCaseTab.expected_output }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    output: [Object, String],
    loading: Boolean,
  },
  data() {
    return this.initialState();
  },
  watch: {
    output(newValue) {
      this.outputData = newValue;
      if (newValue && !newValue.syntaxError) {
        this.activeTestCaseTab = newValue.data[0];
      }
    },
  },
  mounted() {},
  methods: {
    initialState() {
      return {
        outputData: "",
        activeTestCaseTab: "",
        activeTestCaseTabIndex: 0,
      };
    },
    handleTabClick(index) {
      this.activeTestCaseTabIndex = index;
      this.activeTestCaseTab = this.outputData.data[index];
    },
  },
};
</script>

<style src="./TestResultView.css"></style>
