<template>
  <div class="Home">
    <!--This Header needs changed completely  -->
    <div
      class="header-layout-1 home-header bg-wt navlink-layout-1"
    >
      <div
        class="header-layout-1-logoSection home-logo-header"
        @click="$router.push('/')"
      >
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img
 
          class="header-logoIcon"
          src="../assets/loginPageLogo.png"
        />
        <!-- <img v-else class="header-logoImg" src="../assets/loginPageLogo.png" /> -->
      </div>

      <div class="header-layout-1-navLinks">
        <div class="nav-link" @click="$router.push('/')">
          Home
        </div>
        <div class="nav-link" @click="$router.push('/tm-interview')">TM Interviews</div>
      <div class="nav-link" @click="$router.push('/tm-engagement')">TM Engage</div>
      <div class="nav-link" @click="$router.push('/about')">About</div>
      <div class="nav-link" @click="$router.push('/contact')">Contact</div>
    </div>
      <div class="header-layout-1-rightHeader home-right-header">
        <div class="home-login-div">
          <button class="home-login-btn" @click="$router.push('/login')">
            Login
          </button>
        </div>
      </div>

      <div class="home-nav-toggle" @click="toggleNavMenu">
        <b-icon icon="list" class="rounded p-1"></b-icon>
      </div>
      <div class="mobile-nav-links" v-if="isNavMenuVisible">
        <div class="mobile-nav-link" @click="$router.push('/')">
          Home
        </div>
        <div class="mobile-nav-link" @click="$router.push('/tm-interview')">
          TM Interviews
        </div>
        <div class="mobile-nav-link" @click="$router.push('/tm-engagement')">
          TM Engagement
        </div>
        <div class="mobile-nav-link" @click="$router.push('/about')">About</div>
        <div class="mobile-nav-link" @click="$router.push('/contact')">
          Contact
        </div>
    </div>
    </div>

    <!-- page starts -->

    <div class="home-body">
      <div class="hero-container-1 h-30vh hero-bg-1">

        <div class="hero-subtitle  w-full quote-text " style="padding-top: 30px">
          Unlock the Magic of Team Building. Connect with Us Today and Transform Your Team's Virtual Experience!

       </div>
      </div>

     
      <div class="hero-container h-65vh" >
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/letsConnect.png" />
        </div>
        <div class="details-section">
          <div class="section-title">
            Lets Connect
          </div>
          <div class="home-li-item">
            Feel free to reach out for a complimentary quote or request a
            personalized demo or free trial. We're here to assist you in
            exploring our services and discovering the perfect fit for your
            needs.
          </div>
        </div>
      </div>

      <CalendlyWidget calendlyUrl="https://calendly.com/adithya_l-teamsmagic/teamsmagic-product-demo-call" />
      <div class="home-footer">
        <div class="home-footer-body">
          <div class="home-footer-logo-div">
            <img class="footer-tm-log" src="../assets/logoWhite.png" />
          </div>
          <div class="home-footer-about">
            <div>A virtual platform provides employee engagement with technical assessment capabilities for your teams, worldwide.</div>
            <div>Follow us on 
              <b-icon icon="linkedin"  @click="openLinkedIn"></b-icon>
            </div>
            
          </div>
        </div>
        <div class="copywirte-div">
          © {{ currentYear }} Icosphere Technologies. All Rights Reserved. |
          <div class="privacy-tablink" @click="$router.push('/privacy')">
            Privacy
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="default-modal"
      ref="default-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title=""
    >
      <p class="my-4">{{ modalMsg }}</p>
    </b-modal>
  </div>
</template>

<script>
import { demoRequest } from "../api/index";
import CalendlyWidget from '../components/calendly.vue';
export default {
  metaInfo: {
    title: "Contact",
    meta: [
      {
        name: "description",
        content:
          "Connect with Us Today and Transform Your Team's Virtual Experience!",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
  },
  components:{
    CalendlyWidget
  },
  data() {
    return {
      currentYear: "",
      isScrolled: false,
      demoEmail: "",
      modalMsg: false,
      loading: false,
      showJoin:false,
      isJoinInvalid:false,
      joinErrMsg:"",
      showVideo: false,
      isNavMenuVisible: false
    };
  },

  mounted() {
    const d = new Date();
    this.currentYear = d.getFullYear();

     //join Event 
     this.$socket.on("joined", (data) => {
      if(data){
        this.$store.commit("setGameCode", data.gameCode);
        this.$store.commit("setQuestionLength", data.questionLength);
        this.$store.commit("setGameConfig", data.gameConfig);
        let path=this.getEventPath(data.evtCode);
        this.$router.push(path);
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    showJoinModal(){
      this.showJoin =true;
    },
    handleJoinRoom(data){
      this.loading=true;
      let username=data.userName;
      let room =data.roomCode;
      let userData =this.$store.state.userInfo;
      let eventCode ="";
       this.$socket.emit("join",{ username, room,eventCode, userData}, error => {
        console.log("Join err",error);
        if(error){
          this.isJoinInvalid=true;
          this.joinErrMsg=error;
        }else{
          this.$store.commit("setPlayerName", username);
        }
        this.loading=false;
       });
    },
    handleScroll() {
      this.isScrolled = window.pageYOffset > 0;
    },
    requestDemo(e) {
      e.preventDefault();
      this.loading = true;
      console.log("requestDemo", this.demoEmail);
      let payload = {
        email: this.demoEmail,
      };
      demoRequest(payload)
        .then((resp) => {
          console.log("demoRequest success::", resp);
          this.$refs["default-modal"].show();
          this.modalMsg =
            "Thank you for showing your Interest. Our Team will be reach out to you very soon";
          this.loading = false;
          this.demoEmail = "";
        })
        .catch((error) => {
          // this.loading=false;
          console.log("demoRequest error::", error.response.data);
          this.$refs["default-modal"].show();
          this.modalMsg = "Opps Something went wrong. Please try again";
          this.loading = false;
          this.demoEmail = "";
        });
    },
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/teamsmagic/', '_blank');
    },
    toggleNavMenu() {
      this.isNavMenuVisible = !this.isNavMenuVisible;
    },
  },
};
</script>


<style>
.h-30vh{
  height: 30vh;
}
</style>
