<template>
<div class="loginPage">
  <BlockUI v-if="loading" :message="loadingMsg">
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
  </BlockUI>
    <!--This Header needs changed completely  -->
    <div class='header-layout-1 home-header'>
      <div class="header-layout-1-logoSection home-logo-header" @click="$router.push('/')">
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img class="header-logoImg" src="../assets/loginPageLogo.png" />
      </div>
    </div>
  <div class="loginPage">
    <div class="login-container hero-bg-1 ">
      <div class="login-logo-div">
        <img
        class="login-logo-Img"
            src="../assets/loginBgImg.png"
        />
      </div>
      <div class="login-form">
      <b-form v-if="showResetDiv" @submit="resetPassLink" class="loginForm resetPasswordForm">

              <div class="loginInputDiv">
                <div class="loginInputLabel">Email</div>
                <input type="text" v-model="email" ref="playerName" class="loginInput"  required>
              </div>
            <div>
              <button class="loginbtn" >Send Reest Link</button>
            </div>
              <div class="forgotPass fontFamily mt-4" style="text-align:center !important" @click="showAuthLogin">
                  <div>Log in</div>
              </div>
          </b-form>


          <b-form v-if="showAuthLoginDiv" @submit="login" class="login-form">

            <div class="section-title">Log in</div>

              <div class="loginInputDiv">
                <div class="loginInputLabel">Email</div>
                <input type="text" v-model="email" ref="playerName" class="input-layout-1"  required>
              </div>
              <div class="loginInputDiv">
                <div class="loginInputLabel">Password</div>
                <input type="password" v-model="password" ref="playerName" class="input-layout-1" required>
              </div>
              <div class="forgotPass fontFamily" @click="showReset">
                    <div>Forgot password?</div>
              </div>
            <div>
              <button class="loginbtn" >Log in</button>
            </div>
              <div v-if="showCreateLogin" class="forgotPass fontFamily mt-4" style="text-align:center !important;display:none;" @click="showCreatAccount">
                  <div>Create account</div>
              </div>
          

          </b-form>

          <b-form v-if="showCreatAccountDiv" @submit="createUser" class="loginForm CreateForm">
            <div class="SectionTitle">Create account</div>

            <div class="loginInputDiv">
                <div class="loginInputLabel">First Name</div>
                <input type="text" v-model="first_name" ref="playerName" class="loginInput" required>
            </div>
            <div class="loginInputDiv">
                <div class="loginInputLabel">Last Name</div>
                <input type="text" v-model="last_name" ref="playerName" class="loginInput" required>
            </div>
            <div class="loginInputDiv">
                <div class="loginInputLabel">Organization Name</div>
                <input type="text" v-model="org_name" ref="playerName" class="loginInput" required>
            </div>
              <div class="loginInputDiv">
                <div class="loginInputLabel">Email</div>
                <input type="text" v-model="email" ref="playerName" class="loginInput"  required>
              </div>
              <div class="loginInputDiv">
                <div class="loginInputLabel">Password</div>
                <input type="password" v-model="password" ref="playerName" class="loginInput" required>
              </div>

            <div>
              <button type="submit" class="loginbtn">Create</button>
            </div>
          </b-form>
      </div>
    </div>

  </div>
    <b-modal id="error-modal" ref="error-modal" size= 'sm'  buttonSize= 'sm'  headerClass='p-2 border-bottom-0'  footerClass='p-2 border-top-0' hide-header-close  ok-only centered title="Oops.">
    <p class="my-4">{{errMsg}}. Please try again</p>
  </b-modal>
</div>
</template>

<script>

import {CreateUser,Login,sendResetPassEmail} from "../api/index"

export default {
  metaInfo: {
    title: "Log in",
    meta: [
      {
        name: "description",
        content:
          "Log into TeamsMagic to start creating activities and connecting with your to stronger your bonds.",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
  },

  data() {
    return {
      loadingMsg:"Please wait",
      loading:false,


      showResetDiv:false,
      showCreatAccountDiv:false,
      showAuthLoginDiv:true,

      //Create User
      first_name :"",
      last_name :"",
      org_name:"",


      email :"",
      password :"",

      errMsg:"",
    };
  },


  computed: {
    showCreateLogin(){
      return process.env.VUE_APP_SHOW_CREATE_LOGIN =="true"
    }

  },
  created() {
  },
  mounted() {
  },
  methods: {
    showCreatAccount(){
      this.showAuthLoginDiv =false;
      this.showCreatAccountDiv =true;
    },
    showReset(){
      this.showAuthLoginDiv =false;
      this.showResetDiv =true;
    },
    showAuthLogin(){
      this.showResetDiv =false;
      this.showCreatAccountDiv =false;
      this.showAuthLoginDiv =true;
    },
    createUser(event){
       event.preventDefault()
       this.loading=true;
       console.log("create");
       let payload={
          first_name: this.first_name,
          last_name: this.last_name,
          email:this.email,
          password: this.password,
          org_name: this.org_name,
       }
       CreateUser(payload)
                .then((resp) => {
                  this.loading=false;
                  this.$store.commit("setLoggedInUserInfo", resp.data.data);
                  this.$router.push("/homepage");
                })
                .catch((error) => {
                  this.loading=false;
                  console.log("Create error::",error.response.data);
                  this.$refs['error-modal'].show();
                  this.errMsg=error.response.data;
                })
    },
    login(event){
       event.preventDefault();
       this.loading=true;
       console.log("login");
       this.$store.commit("clearState");
       let payload={
          email:this.email,
          password: this.password
       }
       Login(payload)
                .then((resp) => {
                  this.loading=false;
                  this.$store.commit("setLoggedInUserInfo", resp.data.data);
                  this.$router.push("/homepage");
                })
                .catch((error) => {
                  this.loading=false;
                  console.log("login error::",error.response.data);
                 
                 this.$refs['error-modal'].show();
                 this.errMsg=error.response.data;
                })
    },


    resetPassLink(event){
       event.preventDefault();
       this.loading=true;
       let payload={
        email:this.email
       }
       sendResetPassEmail(payload)
          .then((resp) => {
            this.loading=false;
            console.log("sendResetPassEmail success::",resp);
          })
          .catch((error) => {
            this.loading=false;
            console.log("sendResetPassEmail error::",error.response.data);
            
            this.$refs['error-modal'].show();
            this.errMsg=error.response.data;
          })

      
    },
    Addplayer(playerName) {
      this.$socket.emit("Addplayer", playerName);
    },

  }
}
</script>

<style>
.loginPage{
  width: 100%;
  height: 100%;
}
.login-container
  {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 5%;
  height: 100vh;
  align-items: center;
}
.login-logo-Img{
  width: 100%;
}
.login-form{
  margin: auto;
  text-align: center;
  width: 100%;
  padding: 5%;
}
.logoSection{
  margin-left: 77px;
  margin-top: 38px;
  display: flex;
}
.logoIcon{
  height: 40px;
  width: 40px;
}
.logoImg{
  margin-left: 10px;
  margin-top: 6px;
  width: 142px;
  height: 30px;
}
.header{
  height: 10vh;
  display: flex;
  background: white;
  width: 100%;
  position: fixed;
  z-index: 500;

}
.body{
  display: flex;
  height: 90vh;
  background: #F0F8FF;
  /* background-image: url("../assets/starDesign.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 300px 300px; */
  width: 100%;
  position: absolute;
  margin-top: 10vh;
}
.welcomebody{
  margin-left: 77px;
  margin-top: auto;
  margin-bottom: auto;
}
.welcomeText{
  font-size: 71.4pt;
  color: var(--primary-color);
  margin-top: 135px;
  font-weight: bold;
  
}
.loginLogoDiv{
  display: flex;
  margin-top: 50px;
}
.dotcom{
  font-size: 48pt;
  color: #B8DF10;
  font-weight: bold;
  
}
.loginInput{
  border: 1px solid var(--primary-color);
  border-radius: 40px;
  height: 2.688rem;
  width: 26.938rem;
  /* text-align: center; */
  
  letter-spacing: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.defaultbtn{
  background-color: #B8DF10;
  border: none;
  color: white;
  /* padding: 12.5pt; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17.5pt;
  width: 238px;
  font-weight: bold;
  
  border-radius: 50px;
  margin-top: 40px;
  min-height: 3.5rem;

}
.loginbtn{
  background-color: var(--primary-color);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  font-weight: bold;
  
  border-radius: 50px;
  margin-top: 40px;

  /*new css*/
  height: 3.5rem;
  width: 100%;
  font-size: 15pt;
}
.loginForm{
 width: 26.938rem;
 margin: auto;
 text-align: center;
}
.orgNameField{
  margin-top: 40px;
}
.forgotPass{
  text-align: right;
  margin-top: 2px;
  font-size: 10.5pt !important;
  cursor: pointer;
}
.loginFormSection{
  width: 100%;
  margin: auto;

}
.loginInputLabel{
  text-align: left;
  
  letter-spacing: 1px;
  color: var(--primary-color);
  margin-left: 1rem;
}
.loginInputDiv{
  margin-bottom: 1.5rem;
}
.fontFamily{
  
  letter-spacing: 1px;
}
</style>