<template>
  <div class="magicPicturePage">
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div class="header header-layout-2">
    <div class="header-layout-2-logoSection">
         <img
            class="header-logoIcon"
            src="../assets/logoIcon.png"
        />
        <img
            class="header-logoImg"
            src="../assets/loginPageLogo.png"
        />
    </div>
    <frament>
        <div v-if="!showEventForm"  class="header-layout-2-middleSection createDatasetBtnSection">
            <div>
                 <div >Use existing datasets questions</div>
            </div>
            <!-- <div class="createBtnDiv"> -->
                 <button class="btn-layout-2 color-2 createDatasetBtn"  @click="showImportDataModal">   
                    <div><b-icon icon="plus" scale="1.25" variant="white"></b-icon></div>
                    <div>Import</div> 
                </button>
            <!-- </div> -->
            
       
        </div>      

      </frament>

    <div class="header-layout-1-rightHeader">
      <div class="header_nav_link" @click="$router.push('/marketplace')" >
          Marketplace
        <div class="ml-1">
          <b-icon font-scale="0.75" icon="gem" variant="warning" aria-hidden="true"></b-icon>
          </div>
      </div>
        <div class="header_nav_link" @click="$router.push('/homepage')" style="margin-left:20px">
          Home
        </div>
    </div>
  </div>
    <div class="creatActivity_page">
     <dataset-form v-if="showEventForm" @updateEventData="submitEventData"></dataset-form>

      <div v-else class="create_activity_wrapper">
        <div  class="creatActivity_layout">
          <div class="creatActivity_left_panel">
            <draggable
              v-model="questions"
              class="menuItems__questions"
            >
              <div
                v-for="(question, index) in questions"
                :key="question.id"
                :class="[
                  'menuItem',
                  { selected: selectedQuestion === question.id },
                ]"
                @click="selectQuestion(question.id)"
              >
                <div class="menuItem__Title">
                  <span class="questionTypeIcon"></span>
                  <span class="p-2">
                    <b-icon icon="arrows-move"  variant="secondary" class="" font-scale="1" ></b-icon>
                    </span
                  >
                  <span class="menuItem__titleNumber">{{ index + 1 }}</span
                  >.
                  <span class="menuItem__titleText" :title="question.text">{{
                    question.text
                  }}</span>
                  <span class="menuItem__actions">
                    <span
                      class="menuItem__dots"
                      @click.stop="toggleActions(question.id)"
                    >
                      &#x22EE;
                    </span>
                    <div
                      v-if="selectedQuestion === question.id && showActions"
                      class="menuItem__dropdown"
                    >
                      <!-- <span
                        class="menuItem__action"
                        @click.stop="deleteQuestion(question.id)"
                        >&#x1F5D1;</span
                      > -->
                      <b-icon icon="trash-fill"  class="menuItem__action" font-scale="2" @click.stop="deleteQuestion(question.id)"></b-icon>
                      <!-- <span
                        class="menuItem__action"
                        @click.stop="copyQuestion(question.id)"
                        >&#x2398;</span
                      > -->
                      <b-icon icon="files"  class="menuItem__action" font-scale="2" @click.stop="copyQuestion(question.id)"></b-icon>
                    </div>
                  </span>
                </div>
              </div>
            </draggable>
          </div>
          <div class="creatActivity_right_panel">
            <div v-if="selectedQuestion !== null" class="headerRow">
              <span class="selectedQuestionInfo">
                {{ questions.findIndex((q) => q.id === selectedQuestion) + 1 }}
                .
                {{
                  questions.find((question) => question.id === selectedQuestion)
                    .text
                }}
              </span>
              <div class="headerActions">
                <b-icon icon="files"  class="actionIcon copy-icon" font-scale="0.9" @click.stop="copyQuestion(selectedQuestion)"></b-icon>
                <b-icon icon="trash-fill"  class="actionIcon delete-icon" font-scale="0.9"  @click.stop="deleteQuestion(selectedQuestion)"></b-icon>
              </div>
            </div>

            <!-- Render the appropriate input component based on selected question type -->
            <template v-if="selectedQuestion !== null">
              <QuizInput
                v-if="selectedQuestionType === 'fff'"
                :value="selectedQuestionData"
                @input-change="updateQuestionData"
              />
              <MagicPictureInput
                v-else-if="selectedQuestionType === 'mp'"
                :value="selectedQuestionData"
                @input-change="updateQuestionData"
              />
              <ScribbleInput
              v-else-if="selectedQuestionType === 'scbl'"
                :value="selectedQuestionData"
                @input-change="updateQuestionData"
              />
            </template>

          
          </div>
        </div>

      <div class="create_activity_actions">
        <button @click="showConfirmSubmitModal" class="submitButton finishButton">Finish</button>
        <button @click="handleShowAddQuestion" class="submitButton addButton">Add Question</button>
      </div>
    </div>
    </div>
   <Confirmation_modal @finishActivity="submitActivity"></Confirmation_modal>
   <import-data @finishImportedData="finishImportedData"></import-data>
    <add-question @addNewQuestion="addQuestion"></add-question>
   <b-modal id="error-modal" ref="error-modal" size= 'sm'  buttonSize= 'sm'  headerClass='p-2 border-bottom-0'  footerClass='p-2 border-top-0' hide-header-close  ok-only centered title="Oops.">
    <p class="my-4">{{errMsg}}. Please try again</p>
  </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DatasetForm from '../components/CustomCreate/datasetForm.vue';
import QuizInput from "../components/CustomCreate/fff_input.vue";
import MagicPictureInput from "../components/CustomCreate/mp_input.vue";
import ScribbleInput from "../components/CustomCreate/scribble_input.vue";
import {createDataset} from "../api/index"
import Confirmation_modal from '../components/Modal/confirmation_modal.vue';
import ImportData from '../components/Modal/importData.vue';
import AddQuestion from '../components/Modal/addQuestion.vue';

export default {
  name:'Create-activity',
  components: {
    draggable,
    QuizInput,
    MagicPictureInput,
    ScribbleInput,
    DatasetForm,
    Confirmation_modal,
    ImportData,
    AddQuestion
  },
  data() {
    return {
      loading:false,
      loadingMsg:"Creating your activity",
      questions: [],
      selectedQuestion: null,
      showActions: false,
      activityData: [], // Array to store all question data for submission
      questionData: {}, // Object to store input values for each question
      eventDetails:{},
      showEventForm:true,
      errMsg:""
    };
  },
  mounted() {
    // Select the first question by default
    if (this.questions.length > 0) {
      this.selectedQuestion = this.questions[0].id;
    }
  },
  computed: {
    selectedQuestionData() {
      return this.questionData[this.selectedQuestion] || {};
    },
    selectedQuestionType() {
      const question = this.questions.find(
        (question) => question.id === this.selectedQuestion
      );
      return question ? question.type : null;
    },
  },
  methods: {
    generateUniqueId() {
      const timestamp = new Date().getTime(); // Get current timestamp
      const random = Math.floor(Math.random() * 10000); // Generate random number
      return `${timestamp}${random}`; // Combine timestamp and random number to form ID
    },
    submitEventData(data){
      this.eventDetails=data;
      this.showEventForm=false;
      this.handleShowAddQuestion();
    },

    selectQuestion(questionId) {
      this.selectedQuestion = questionId;
      this.showActions = false; // Hide actions if a different question is selected
    },
    toggleActions(questionId) {
      if (this.selectedQuestion === questionId) {
        this.showActions = !this.showActions;
      } else {
        this.selectedQuestion = questionId;
        this.showActions = true;
      }
    },
    deleteQuestion(questionId) {
      const index = this.questions.findIndex(
        (question) => question.id === questionId
      );
      if (index !== -1) {
        this.questions.splice(index, 1);
      }

      // Set the selected question to the one just above the deleted one
      if (this.questions.length > 0) {
        if (index > 0) {
          this.selectedQuestion = this.questions[index - 1].id;
        } else {
          this.selectedQuestion = this.questions[0].id;
        }
      } else {
        this.selectedQuestion = null;
      }

      //delete from questionData
      if (Object.prototype.hasOwnProperty.call(this.questionData,questionId)) {
        delete this.questionData[questionId];
      }

      this.showActions = false; // Hide actions after deletion
    },
    copyQuestion(questionId) {
      const questionToCopy = this.questions.find(
        (question) => question.id === questionId
      );
      if (questionToCopy) {
        const newId = this.generateUniqueId();
        const newQuestion = { ...questionToCopy, id: newId };
        this.questions.push(newQuestion);

        // Copy the question data as well
        if (this.questionData[questionId]) {
          let copiedQuestionData =this.questionData[questionId];
          const newQuestionData = { ...copiedQuestionData, id: newId };
          this.$set(this.questionData, newId, newQuestionData);
        }
      }
      this.showActions = false; // Hide actions after copying
    },
    handleShowAddQuestion(){
      this.$bvModal.show('add-question-modal');
    },
    addQuestion(q_type) {
        const newId = this.generateUniqueId();
        const newQuestion = {  id: newId , text: "", type: q_type };
        this.questions.push(newQuestion);
        const newQuestionData = { id: newId };
        this.$set(this.questionData, newId, newQuestionData);
        this.selectedQuestion = newId;
        this.showActions = false; // Hide actions after copying
    },

    updateQuestionData(data) {
      const questionIndex = this.questions.findIndex(
        (q) => q.id === this.selectedQuestion
      );
      if (questionIndex !== -1) {
        this.questions[questionIndex].text = data.questionText;
        this.questionData[this.selectedQuestion] = data;
      }
    },
    showConfirmSubmitModal(){
      this.$bvModal.show('confirmation-modal');
    },
    showImportDataModal(){
      this.$bvModal.show('import-modal');
    },
    submitActivity() {
      this.loading=true;
      let randomnum=Math.floor(Math.random() * 100000);
      let dataCodeName = this.eventDetails.Created_By.toLowerCase().split(" ")[0];
      var regexPattern = /[^A-Za-z0-9]/g;
      var ans = dataCodeName.replace(regexPattern, "");
      let data_code= ans+randomnum.toString();
      let data = new FormData();
      data.append("data_code", data_code);
      data.append("name", this.eventDetails.Title);
      data.append("created_by", this.eventDetails.Created_By);
      data.append("event_type", "Custom");
      data.append("event_code", "-");
      data.append("category", "Entertainment");
      data.append("publish_type", this.eventDetails.Publish_Type);
      data.append("previewfile", this.eventDetails.PreviewFile);

      let files = [];
      for (let i = 0; i < this.questions.length; i++) {
        let q_data = this.questionData[this.questions[i].id];
        const q_url = q_data.question_url|| "";
        let questionObj = {
          id: this.questions[i].id,
          type: this.questions[i].type,
          question_no: i + 1,
          question: (q_data.questionText)?q_data.questionText:"-",
          answer: q_data.answer,
          options: q_data.options||"",
          question_url: (q_url.startsWith('http://') || q_url.startsWith('https://') || q_url.startsWith('www.'))?q_url:"" ,
          scribble_word: q_data.questionText,
        };
        if (this.questions[i].type === "mp") {
          let file = this.questionData[this.questions[i].id].file || false;
          if (file) {
            const renamedFile = new File([file], this.questions[i].id, {
              type: file.type,
            });
            files.push(renamedFile);
          }
        }
        this.activityData.push(questionObj);
      }
      data.append("questions", JSON.stringify(this.activityData));

      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("file", file);
      }

       createDataset(data)
                  .then((resp) => {
                  console.log("resp",resp);
                  this.loading= false;
                  this.$router.push("/marketplace");
                  })
                  .catch((err) => {
                  console.log("Error",err);
                  this.loading= false;
                  this.errMsg= "Something went wrong, please refresh and try creating activity"
                  this.$bvModal.show("error-modal");
                  });
    },
    finishImportedData(data){
      for(let i =0;i<data.length;i++){
        this.deleteQuestion(data[i].id);
      }
      data.map((item)=>{
        this.questions.push(item);
        this.$set(this.questionData, item.id, item);
        this.showActions = false; // Hide actions after copying
      })
    }
  },
};
</script>

<style >
.creatActivity_page {
  width: 100%;
  margin-top: 10vh;
  position: absolute;
}
.creatActivity_layout {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 67%;
  height: 78vh;
  box-sizing: border-box;
}
.creatActivity_left_panel {
  background: #f6f6f6;
  height: 100%;
  box-sizing: border-box;
  padding: 1%;
  overflow: hidden; /* Changed from 'auto' to 'hidden' */
}
.creatActivity_right_panel {
  overflow: auto;
}
.menuItems__questions {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto; /* Added to allow scrolling within the container */
}
.menuItem {
  height: 12%;
  align-items: center;
  display: flex;
  padding: 3%;
  font-weight: bold;
  font-size: 1.2vw;
  margin-top: 3%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  border-radius: 1rem;
  background: #ffffff;
  color: var(--primary-color);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
.menuItem.selected {
  background: linear-gradient(to top, var(--primary-color) 0%, #5614ac 100%) !important;
  color: #ffffff;
}
.menuItem__Title {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menuItem__titleText {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menuItem__actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.menuItem__dots {
  cursor: pointer;
  margin-left: 1rem;
}
.menuItem__dropdown {
  position: absolute;
  top: 0;
  right: 1.5rem; /* Adjust the value as needed to position it correctly */
  display: flex;
  flex-direction: row; /* Change to row for horizontal layout */
  border-radius: 0.5rem;
  z-index: 10;
  padding: 0.5rem; /* Optional: add padding for better spacing */
  height: -webkit-fill-available;
  align-items: center;
  background: linear-gradient(to top, var(--primary-color) 0%, #5614ac 100%) !important;
}

.menuItem__action {
  padding: 0.5rem; /* Adjust padding for better spacing */
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.menuItem__action:hover {
  background: #280755;
  border-radius: 0.5rem;
}
.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  background: #ffffff;
  border-bottom: 2px solid #ddd;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.selectedQuestionInfo {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.headerActions {
  display: flex;
  align-items: center;
}

.headerActions .actionIcon {
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.5rem;
  color: #666;
  transition: color 0.3s, transform 0.3s;
}

.headerActions .actionIcon:hover {
  color: #000;
  transform: scale(1.2);
}
.questionInput {
  width: 100%;
  height: 150px; /* Adjust height as needed */
  resize: none;
  margin: 1rem 0;
  padding: 0.5rem;
}


.create_activity_actions {
  display: flex;
  justify-content: flex-end;
  padding: 1%;
  background-color: #f6f6f6;
  border-top: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.submitButton {
  padding: 0.75% 1.5%;
  margin-left: 1%;
  border: none;
  border-radius: 8px;
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.submitButton:disabled,
.submitButton.disabled {
  /* Styles for disabled button */
  background-color: #cccccc; /* Example: Gray */
  color: #666666; /* Example: Darker Gray */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
  opacity: 0.65; /* Optional: Make it semi-transparent */
}

.finishButton {
  background-color: #4CAF50; /* Green */
  color: white;
}

.finishButton:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.addButton {
  background-color: var(--primary-color); /* Blue */
  color: white;
}

.addButton:hover {
  background-color: #280755;
  transform: translateY(-2px);
}
.copy-icon{
  color:var(--primary-color) !important;
}
.delete-icon{
  color:red !important;
}

</style>
