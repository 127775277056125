<template>
  <header class="header-1">
    <div class="header-1-content">
      <h1 class="header-1-title">{{ activeTabTitle }}</h1>
      <h2 v-if="activeTabSubtitle" class="header-1-subtitle">
        {{ activeTabSubtitle }}
      </h2>
    </div>
  </header>
</template>

<script>
import { tabs } from "../../mixins/tabs";
export default {
  computed: {
    activeTabTitle() {
      const activeTab = this.getActiveTab();
      return activeTab ? activeTab?.name : "";
    },
    activeTabSubtitle() {
      const activeTab = this.getActiveTab();
      if (activeTab?.route === "/homepage") {
        const userName = this.$store.state.playerName;
        return activeTab && activeTab?.subtitle
          ? activeTab?.subtitle + ", " + userName
          : null;
      }
      return activeTab && activeTab?.subtitle ? activeTab?.subtitle : null;
    },
  },
  methods: {
    getActiveTab() {
      const route = this.$route.path;
      return tabs.find((tab) => tab.route === route);
    },
  },
};
</script>

<style scoped>
.header-1 {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 15px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 999;
  height: 75px;
}

.header-1-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-1-title {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-bold);
  font-size: 24px;
  color: #390879;
  margin: 0;
}

.header-1-subtitle {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular);
  font-size: 16px;
  color: #666;
  margin: 5px 0 0;
}

.header-1:hover {
  background-color: #ececec;
}
</style>
