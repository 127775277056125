export const tags =[
    {
        "text": "Array"
    },
    {
        "text": "String"
    },
    {
        "text": "Hash Table"
    },
    {
        "text": "Dynamic Programming"
    },
    {
        "text": "Math"
    },
    {
        "text": "Sorting"
    },
    {
        "text": "Greedy"
    },
    {
        "text": "Depth-First Search"
    },
    {
        "text": "Database"
    },
    {
        "text": "Binary Search"
    },
    {
        "text": "Matrix"
    },
    {
        "text": "Tree"
    },
    {
        "text": "Breadth-First Search"
    },
    {
        "text": "Bit Manipulation"
    },
    {
        "text": "Two Pointers"
    },
    {
        "text": "Binary Tree"
    },
    {
        "text": "Heap (Priority Queue)"
    },
    {
        "text": "Prefix Sum"
    },
    {
        "text": "Simulation"
    },
    {
        "text": "Stack"
    },
    {
        "text": "Graph"
    },
    {
        "text": "Counting"
    },
    {
        "text": "Sliding Window"
    },
    {
        "text": "Design"
    },
    {
        "text": "Backtracking"
    },
    {
        "text": "Enumeration"
    },
    {
        "text": "Union Find"
    },
    {
        "text": "Linked List"
    },
    {
        "text": "Ordered Set"
    },
    {
        "text": "Monotonic Stack"
    },
    {
        "text": "Number Theory"
    },
    {
        "text": "Trie"
    },
    {
        "text": "Segment Tree"
    },
    {
        "text": "Bitmask"
    },
    {
        "text": "Divide and Conquer"
    },
    {
        "text": "Queue"
    },
    {
        "text": "Recursion"
    },
    {
        "text": "Binary Search Tree"
    },
    {
        "text": "Combinatorics"
    },
    {
        "text": "Binary Indexed Tree"
    },
    {
        "text": "Geometry"
    },
    {
        "text": "Memoization"
    },
    {
        "text": "Hash Function"
    },
    {
        "text": "Topological Sort"
    },
    {
        "text": "String Matching"
    },
    {
        "text": "Shortest Path"
    },
    {
        "text": "Game Theory"
    },
    {
        "text": "Rolling Hash"
    },
    {
        "text": "Interactive"
    },
    {
        "text": "Data Stream"
    },
    {
        "text": "Brainteaser"
    },
    {
        "text": "Monotonic Queue"
    },
    {
        "text": "Randomized"
    },
    {
        "text": "Merge Sort"
    },
    {
        "text": "Doubly-Linked List"
    },
    {
        "text": "Iterator"
    },
    {
        "text": "Concurrency"
    },
    {
        "text": "Probability and Statistics"
    },
    {
        "text": "Counting Sort"
    },
    {
        "text": "Quickselect"
    },
    {
        "text": "Suffix Array"
    },
    {
        "text": "Bucket Sort"
    },
    {
        "text": "Minimum Spanning Tree"
    },
    {
        "text": "Shell"
    },
    {
        "text": "Line Sweep"
    },
    {
        "text": "Reservoir Sampling"
    },
    {
        "text": "Strongly Connected Component"
    },
    {
        "text": "Eulerian Circuit"
    },
    {
        "text": "Radix Sort"
    },
    {
        "text": "Rejection Sampling"
    },
    {
        "text": "Biconnected Component"
    }
]