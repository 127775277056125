import * as XLSX from "xlsx";


export const formatResults = (results) => {
  return results.map((result) => {
    return {
      CandidateId: result.CandidateId,
      InterviewId: result.InterviewId,
      FullName: result.FullName,
      Email: result.Email,
      Status: result.Status,
      RegistrationDt_Tm: new Date(result.RegistrationDt_Tm).toLocaleString(),
      AverageScore: result.AverageScore
    }
});
};


export const exportResults = (results) => {
  const formattedResults = formatResults(results);

  // Convert the results to a worksheet
  const ws = XLSX.utils.json_to_sheet(formattedResults);

  // Create a workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Results");

  // Generate an Excel file and trigger the download
  const fileName = "Interview_results.xlsx";
  XLSX.writeFile(wb, fileName);

  return true;
};
