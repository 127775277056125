<template>
  <div class="Home">
    <!--This Header needs changed completely  -->
    <div
      class="header-layout-1 home-header navlink-layout-1"
      :class="{ 'bg-wt': isScrolled }"
      id="home-header"
    >
      <div
        class="header-layout-1-logoSection home-logo-header"
        @click="$router.push('/')"
      >
        <img class="header-logoIcon" src="../assets/logoIcon.png" />
        <img class="header-logoIcon" src="../assets/loginPageLogo.png" />
        <!-- <img v-else class="header-logoImg" src="../assets/loginPageLogo.png" /> -->
      </div>
      <div class="header-layout-1-navLinks">
        <div class="nav-link" @click="$router.push('/')">Home</div>
        <div class="nav-link" @click="$router.push('/tm-interview')">
          TM Interviews
        </div>
        <div class="nav-link" @click="$router.push('/tm-engagement')">
          TM Engage
        </div>
        <div class="nav-link" @click="$router.push('/about')">About</div>
        <div class="nav-link" @click="$router.push('/contact')">Contact</div>
      </div>

      <div class="header-layout-1-rightHeader home-right-header">
        <!-- <div class="mr-3  home-login-div">
          <button class="home-login-btn" @click="showJoinModal">
            Join Room
          </button>
        </div> -->
        <div class="home-login-div">
          <button class="home-login-btn" @click="$router.push('/login')">
            Login
          </button>
        </div>
      </div>

      <div class="home-nav-toggle" @click="toggleNavMenu">
        <b-icon icon="list" class="rounded p-1"></b-icon>
      </div>
      <div class="mobile-nav-links" v-if="isNavMenuVisible">
        <div class="mobile-nav-link" @click="$router.push('/')">Home</div>
        <div class="mobile-nav-link" @click="$router.push('/tm-interview')">
          TM Interviews
        </div>
        <div class="mobile-nav-link" @click="$router.push('/tm-engagement')">
          TM Engagement
        </div>
        <div class="mobile-nav-link" @click="$router.push('/about')">About</div>
        <div class="mobile-nav-link" @click="$router.push('/contact')">
          Contact
        </div>
      </div>
    </div>

    <!-- page starts -->

    <div class="home-body">
      <!-- <div class="hero-container-1 hero-bg-img1">
        <div class="hero-title color-3">
          Unleash the Power of Teamwork
        </div>
        <div class="hero-subtitle color-3">
          Engage, Connect, and Succeed Together in the Virtual World
        </div>
        <div class="hero-subtitle color-3 hero-cta">
          <button class="btn-layout-2 bg-cl-1 cta-btn border" @click="scrolltoEmail">
            Get a Demo
          </button>
        </div>
      </div> -->

      <div class="hero-container hero-video">
        <div class="details-section ta-center">
          <div class="gameTitle text-main-title">
            Unleash Team Potential with TeamsMagic!
          </div>
          <div class="gameAbout details-section-about">
            <div class="subheadline color-3">
              Seamless Team-Building & Assessments in One Place!
            </div>
          </div>
          <div class="cta-btn-section">
            <div class="gameJoinBtn details-section-infoBtn cta-btn-container">
              <button
                class="btn-layout-2 bg-cl-1 cta-btn"
                @click="scrolltoEmail"
                style="box-shadow: 3px 4px #f3f3f3"
              >
                Get a Demo
              </button>
            </div>
          </div>
        </div>
        <div class="hero-section-video-div">
          <img class="hero-section-logo-2 hero-section-logo-3" src="../assets/tm_main_bg3.jpeg" />
        </div>
      </div>

      <div class="home-intro-container main-intro-conatiner">
        <div class="intro-container-wrapper">
          <div class="section-title intro-section-title">TeamsMagic</div>
          <div class="intro-section-body text-justify">
            The ultimate platform for platform that seamlessly integrates both
            team-building and technical assessment capabilities to enhance
            productivity and hiring processes.
            <div class="mt-2">
              <b>Key Features:-</b>
              <div class="text-md p-3">
                <div class="d-flex">
                  <div class="t-color-2">
                    <b-icon icon="check2-circle"></b-icon>
                  </div>
                  <div class="pl-2 text-left">
                    TM Engage: Enhanced team connections, productivity boosts,
                    and enjoyable activities
                  </div>
                </div>
              </div>
              <div class="text-md p-3">
                <div class="d-flex">
                  <div class="t-color-2">
                    <b-icon icon="check2-circle"></b-icon>
                  </div>
                  <div class="pl-2 text-left">
                    TM Interviews: Streamlined assessments, real-time reports,
                    and advanced cheating detection.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hero-section-logo-div-3">
          <img
            class="hero-section-logo w-full"
            src="../assets/tm_cheering.png"
          />
        </div>
      </div>

      <div class="hero-container">
        <div class="hero-section-logo-div-3">
          <img
            class="hero-section-logo"
            src="../assets/tm_interview_main_bg1.png"
          />
        </div>
        <div class="details-section">
          <div class="section-title">TM Interviews</div>
          <div class="home-li-item text-md">
            <div>
              <div>
                TM Interviews streamlines technical assessments with 100+ coding
                problems across key areas like DSA and dynamic programming,
                supporting languages like C, Java, Python, and more. With
                instant interview setup, real-time reports, and advanced
                cheating detection (e.g., copy-paste, tab-switching), it ensures
                fair, efficient, and accurate evaluations—all in one seamless
                platform.
              </div>
              <div class="mt-4">
                <button
                  class="btn-layout-2 bg-cl-1 w-half visit-btn"
                  @click="$router.push('/tm-interview')"
                  style="box-shadow: 3px 4px #f3f3f3"
                >
                  Visit TM Interviews
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-container hero-bg-1">
        <div class="details-section">
          <div class="section-title">TM Engage</div>
          <div class="home-li-item text-md">
            <div>
              <div>
                TM Engage offers a dynamic range of 50+ themed games and
                activities, from trivia to scavenger hunts, designed to
                strengthen team bonds and boost communication. With customizable
                events, you can tailor activities to any theme or goal, while
                unlimited rooms allow you to host multiple sessions
                simultaneously, engaging different teams at once. Enhance your
                team's collaboration and morale with fun, interactive
                experiences—all in one platform.
              </div>
              <div class="mt-4">
                <button
                  class="btn-layout-2 bg-cl-1 w-half visit-btn"
                  @click="$router.push('/tm-engagement')"
                  style="box-shadow: 3px 4px #f3f3f3"
                >
                  Visit TM Engage
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/tm_engage_bg1.jpeg" />
        </div>
      </div>

      <div class="hero-container h-65vh" id="email-input-section">
        <div class="hero-section-logo-div-3">
          <img class="hero-section-logo" src="../assets/letsConnect.png" />
        </div>
        <div class="details-section">
          <div class="section-title">Lets Connect</div>
          <div class="home-li-item">
            Feel free to reach out for a complimentary quote or request a
            personalized demo or free trial. We're here to assist you in
            exploring our services and discovering the perfect fit for your
            needs.
          </div>
        </div>
      </div>
      <CalendlyWidget
        calendlyUrl="https://calendly.com/adithya_l-teamsmagic/teamsmagic-product-demo-call"
      />

      <div class="home-footer">
        <div class="home-footer-body">
          <div class="home-footer-logo-div">
            <img class="footer-tm-log" src="../assets/logoWhite.png" />
          </div>
          <div class="home-footer-about">
            <div>
              A virtual platform provides employee engagement with technical
              assessment capabilities for your teams, worldwide.
            </div>
            <div>
              Follow us on
              <b-icon icon="linkedin" @click="openLinkedIn"></b-icon>
            </div>
          </div>
        </div>
        <div class="copywirte-div">
          © {{ currentYear }} Icosphere Technologies. All Rights Reserved. |
          <div class="privacy-tablink" @click="$router.push('/privacy')">
            Privacy
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="default-modal"
      ref="default-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title=""
    >
      <p class="my-4">{{ modalMsg }}</p>
    </b-modal>
    <JoinModal
      :isVisible="showJoin"
      @update:isVisible="showJoin = $event"
      @update:isInvalid="isJoinInvalid = $event"
      @handleSubmit="handleJoinRoom($event)"
      :isInvalid="isJoinInvalid"
      :errMsg="joinErrMsg"
    >
    </JoinModal>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";
import JoinModal from "../components/Modal/joinModal.vue";
import CalendlyWidget from "../components/calendly.vue";
export default {
  metaInfo: {
    title:
      "TeamsMagic: #1 Employee engagement and technical assessment platform",
    meta: [
      {
        name: "description",
        content:
          "Elevate your team's performance with TeamsMagic! Discover a complete solution for seamless collaboration, engaging virtual activities, and powerful technical assessments—all designed to strengthen bonds and drive productivity.",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
    script: [
      {
        type: "application/ld+json",
        json: {
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "TeamsMagic",
          url: "https://www.teamsmagic.com",
          logo: "https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/TM_logo.png",
          sameAs: ["https://www.linkedin.com/company/teamsmagic"],
          contactPoint: [
            {
              "@type": "ContactPoint",
              telephone: "+91-94892-10341",
              contactType: "Office",
            },
          ],
        },
      },
    ],
  },
  data() {
    return {
      currentYear: "",
      isScrolled: false,
      demoEmail: "",
      modalMsg: false,
      loading: false,
      showJoin: false,
      isJoinInvalid: false,
      joinErrMsg: "",
      showVideo: false,
      isNavMenuVisible: false,

      trendingActivityList: [
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic13825/1716814292852657344.jpeg",
        },
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic6236/scavenger_thumbnail.jpeg",
        },
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic6180/1718961972611036822.jpeg",
        },
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/fff-container/1714903115253223987.jpeg",
        },
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/fff-container/1715877542534951366.jpeg",
        },
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/scbl-container/1714900915426029312.jpeg",
        },
        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/scbl-container/1714900110659087025.jpeg",
        },

        {
          preview_url:
            "https://teamsmagicdevstorage.blob.core.windows.net/teamsmagic58136/thumbnail_for_Guess_the_Logo_game.jpg",
        },
      ],
    };
  },

  mounted() {
    console.log("new apk->", 0.4);
    const d = new Date();
    this.currentYear = d.getFullYear();

    // ScrollReveal starts
    ScrollReveal().reveal(".hero-section-logo-div-3", {
      origin: "right",
      delay: 400,
      distance: "60px",
      duration: 500,
      easing: "ease-in",
      scale: 0.85,
    });
    ScrollReveal().reveal(".details-section", {
      origin: "left",
      delay: 400,
      distance: "60px",
      duration: 500,
      easing: "ease-in",
      scale: 0.85,
    });
    ScrollReveal().reveal(".feature-item", {
      origin: "top",
      delay: 400,
      distance: "60px",
      duration: 500,
      easing: "ease-in",
      scale: 0.85,
    });

    // ScrollReveal ends

    window.addEventListener("scroll", this.handleScroll);

    //join Event
    this.$socket.on("joined", (data) => {
      if (data) {
        this.$store.commit("setGameCode", data.gameCode);
        this.$store.commit("setQuestionLength", data.questionLength);
        this.$store.commit("setGameConfig", data.gameConfig);
        let path = this.getEventPath(data.evtCode);
        this.$router.push(path);
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  components: { JoinModal, CalendlyWidget },

  methods: {
    showJoinModal() {
      this.showJoin = true;
    },
    handleJoinRoom(data) {
      this.loading = true;
      let username = data.userName;
      let room = data.roomCode;
      let userData = this.$store.state.userInfo;
      let eventCode = "";
      this.$socket.emit(
        "join",
        { username, room, eventCode, userData },
        (error) => {
          if (error) {
            this.isJoinInvalid = true;
            this.joinErrMsg = error;
          } else {
            this.$store.commit("setPlayerName", username);
          }
          this.loading = false;
        }
      );
    },
    handleScroll() {
      this.isScrolled = window.pageYOffset > 0;
    },
    scrolltoEmail() {
      var access = document.getElementById("email-input-section");
      access.scrollIntoView({ behavior: "smooth" }, true);
    },
    loadVideo() {
      this.showVideo = true;
    },
    openLinkedIn() {
      window.open("https://www.linkedin.com/company/teamsmagic/", "_blank");
    },
    toggleNavMenu() {
      this.isNavMenuVisible = !this.isNavMenuVisible;
    },
  },
};
</script>

<style>
.h-65vh {
  height: 65vh !important;
}
.img-home-slide {
  width: 320px !important;
  height: 180px;
  margin: 10px;

  transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(1deg);
  box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, 0.5),
    1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border-radius: 0.5em;
}

.img-home-slide:hover {
  transform: rotate3d(0, 0, 0, 0deg) rotate(0deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Optional: adjust the shadow on hover */
}

.stroke-white {
  -webkit-text-stroke: 1px #000;
}
.hero-title {
  font-weight: 700;
  text-align: center;
  width: 60%;
  letter-spacing: 2px;
  margin: auto;
  font-size: 72px;
}
.hero-subtitle {
  width: 35%;
  text-align: center;
  margin: auto;
  font-size: var(--text-2lg);
}
.hero-cta {
  width: 20%;
}
.hero-bg-img1 {
  background-image: url("../assets/TeamsMagicBG1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-body {
  width: 100%;
  height: 100%;
}
.hero-section-logo-div-3 {
  text-align: center;
}
.ta-center {
  text-align: center !important;
}
.hero-bg-1 {
  background: linear-gradient(125deg, #f0f8ff 40%, #ffffff 100%);
}
.bg-cl-1 {
  background-color: var(--primary-color);
}
.subheadline {
  width: 65%;
  margin: auto;
}
.cta-btn-section {
  width: 100%;
}
.cta-btn-container {
  margin: auto;
  padding-top: 5%;
  width: 30% !important;
}
.cta-btn {
  padding: 5% !important;
  align-items: center;
  justify-content: center;
  display: flex;
}
.home-intro-container {
  display: grid;
  grid-template-columns: 58% 40%;
  padding: 5%;
  height: 80vh;
  align-items: center;
}
.intro-container-wrapper {
  height: 100%;
  width: 100%;
  background: #f4f4f4;
  border-radius: 50px;
}
.intro-section-title {
  padding: 5%;
  padding-bottom: 0%;
}
.home-features-container {
  display: grid;
  grid-template-rows: 14% 7% 70% 9%;
  padding: 5%;
  height: 100vh;
  align-items: center;
}
.section-title {
  font-size: var(--text-2xl);
  color: var(--primary-color);
  font-weight: bold;
}
.w-full {
  width: 100% !important;
}
.text-justify {
  text-align: justify;
  text-justify: inter-word;
}
.intro-section-body,
.feature-title {
  font-size: var(--text-lg);
  color: var(--primary-color);

  text-align: center;
  letter-spacing: 1px;
  padding: 5%;
}
.intro-section-footer {
  text-align: right;
}
.intro-section-footer {
  height: 100%;
}
.intro-section-footer-img {
  height: 100%;
}
.features-container {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 2%;
}
.cloud-design-container {
  width: 100%;
}
.text-right {
  text-align: right;
}
.feature-item {
  width: 100%;
  height: 90%;
  border-radius: 10%;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-rows: 20% 25% 2% 47%;
  gap: 2%;
}
.feature-icon-div {
  color: #b8df10;
  font-size: 3vw;
}
.feature-divider {
  border-top: 3px solid #bbb;
}
.feature-body {
  height: 100%;
  font-size: var(--text-md);
  text-align: center;
  letter-spacing: 1px;
  padding: 5%;
}
.home-li-item {
  font-size: var(--text-lg);

  text-align: center;
  letter-spacing: 1px;
  color: var(--primary-color);
  display: flex;
  text-align: left;
}
.home-email-input {
  font-size: 1.5vw;

  text-align: center;
  letter-spacing: 1px;
  color: var(--primary-color);
  text-align: left;
}
.email-input,
.input-layout-1 {
  width: 100%;
  border-radius: 50px;
  border: 1px solid var(--primary-color);
  padding: 2%;
}
.home-footer {
  height: 50vh;
  width: 100%;
  background: var(--primary-color);
  color: white;
  align-items: center;
  padding: 5%;

  display: grid;
  grid-template-rows: 87% 10%;
  gap: 3%;

  font-size: var(--text-md);

  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 2%;
}
.home-footer-body {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}
.home-footer-logo-div {
  width: 80%;
  margin: auto;
}
.home-footer-about {
  display: grid;
  align-items: center;
}
.home-login-btn {
  color: #ffffff;
  border: 0px;
  border-radius: 50px;
  padding-left: 2rem;
  padding-right: 2rem;
  background: var(--primary-color);
}
.home-header {
  background-color: transparent !important;
  transition: background-color 0.3s ease !important;
}
.bg-wt {
  background-color: white !important;
}
.copywirte-div {
  display: flex;
  margin: auto;
}
.privacy-tablink {
  cursor: pointer;
}

.rounded-10 {
  border-radius: 10%;
}

.shadow-2 {
  box-shadow: 0.5rem 0.5rem var(--primary-color) !important;
}

.hero-section-video-div {
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  align-items: center;
  border-radius: 50%;
  margin: auto;
  background: var(--primary-color);
}

.hero-video {
  grid-template-columns: 45% 55% !important;
  background: linear-gradient(to bottom, #ffffff, var(--primary-color));
}
.bg-primary-gradient {
  background: linear-gradient(to bottom, #ffffff, var(--primary-color));
}
.promo-video-border {
  border: 5px solid #000000;
}

.home-nav-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5em;
  padding: 10px;
}

.mobile-nav-links {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1000;
}

.mobile-nav-link {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-nav-link:hover {
  background-color: #f1f1f1;
}

.header-layout-1-navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  margin: 0 15px;
  cursor: pointer;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}
.text-left {
  text-align: left !important;
}
.text-main-title {
  font-size: 44px !important;
}
.navlink-layout-1 {
  grid-template-columns: 20% 60% 20% !important;
}
.visit-btn {
  border: 2px solid !important;
  font-size: var(--text-md) !important;
  padding: 12px !important;
}

.hero-section-logo-3 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: perspective(700px) rotateX(8deg) rotateY(-10deg);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  border-radius: 10px; /* Rounded corners */
}

.hero-section-logo-3:hover {
  transform: perspective(800px) rotateX(0deg) rotateY(0deg) scale(1.05);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
}

@media (max-width: 600px) {
  .hero-title {
    width: 80%;
    font-size: 48px;
  }
  .hero-subtitle {
    width: 55%;
    font-size: 16px;
  }
  .hero-cta {
    width: 50%;
  }
  .hero-container {
    grid-template-columns: 1fr !important;
  }
  .home-intro-container {
    grid-template-columns: 1fr !important;
  }
  .main-intro-conatiner {
    height: 100vh !important;
  }
  .home-footer-body {
    grid-template-columns: 1fr !important;
    gap: 1rem !important;
  }
  .features-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .home-right-header {
    display: none !important;
  }
  .home-logo-header {
    justify-content: center !important;
  }
  .home-header {
    grid-template-columns: 90% 10% !important;
  }

  .header-layout-1-navLinks {
    display: none;
  }

  .home-nav-toggle {
    display: block;
  }

  .mobile-nav-links {
    display: flex;
  }
}
@media (max-width: 500px) {
  .hero-container {
    grid-template-columns: 1fr !important;
  }
  .home-intro-container {
    grid-template-columns: 1fr !important;
  }
  .main-intro-conatiner {
    height: 100vh !important;
  }
  .home-footer-body {
    grid-template-columns: 1fr !important;
    gap: 1rem !important;
  }
  .features-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .gameTitle {
    font-size: 8vw !important;
  }
  .subheadline {
    font-size: 3vw !important;
  }
  .btn-layout-2,
  .home-li-item,
  .home-email-input {
    font-size: 3vw !important;
  }
  .section-title,
  .feature-icon-div {
    font-size: 6vw !important;
  }
  .intro-section-body,
  .feature-title {
    font-size: 3.4vw !important;
  }
  .feature-body,
  .home-footer {
    font-size: 2.4vw !important;
  }
}
</style>
