export const codeDatatypes = [
  {
    data_type: "int",
    description: "Integer type",
    Java: "int",
    C: "int",
    CPlusPlus: "int",
    Python: "int",
    JavaScript: "Number",
    example_values: "5",
  },
  {
    data_type: "float",
    description: "Floating-point number",
    Java: "float",
    C: "float",
    CPlusPlus: "float",
    Python: "float",
    JavaScript: "Number",
    example_values: "3.14",
  },
  {
    data_type: "double",
    description: "Double precision floating-point number",
    Java: "double",
    C: "double",
    CPlusPlus: "double",
    Python: "float",
    JavaScript: "Number",
    example_values: "3.14159",
  },
  {
    data_type: "char",
    description: "Single character",
    Java: "char",
    C: "char",
    CPlusPlus: "char",
    Python: "str (length 1)",
    JavaScript: "String",
    example_values: "'A'",
  },
  {
    data_type: "string",
    description: "Sequence of characters",
    Java: "String",
    C: "char*",
    CPlusPlus: "std::string",
    Python: "str",
    JavaScript: "String",
    example_values: '"Hello"',
  },
  {
    data_type: "boolean",
    description: "Boolean (true/false)",
    Java: "boolean",
    C: "_Bool",
    CPlusPlus: "bool",
    Python: "bool",
    JavaScript: "Boolean",
    example_values: "true",
  },
  {
    data_type: "array[int]",
    description: "Array of integers",
    Java: "int[]",
    C: "int[]",
    CPlusPlus: "int[]",
    Python: "List[int]",
    JavaScript: "Array<number>",
    example_values: "[1, 2, 3]",
  },
  {
    data_type: "array[float]",
    description: "Array of floating-point numbers",
    Java: "float[]",
    C: "float[]",
    CPlusPlus: "float[]",
    Python: "List[float]",
    JavaScript: "Array<number>",
    example_values: "[1.1, 2.2, 3.3]",
  },
  {
    data_type: "array[string]",
    description: "Array of strings",
    Java: "String[]",
    C: "char**",
    CPlusPlus: "std::vector<std::string>",
    Python: "List[str]",
    JavaScript: "Array<string>",
    example_values: '["a", "b", "c"]',
  },
  {
    data_type: "map[string, int]",
    description: "Key-value pairs with string keys and integer values",
    Java: "Map<String, Integer>",
    C: "NA",
    CPlusPlus: "std::map<std::string,int>",
    Python: "Dict[str, int]",
    JavaScript: "Object<string, number>",
    example_values: '{"key1": 1, "key2": 2}',
  },
  {
    data_type: "map[string, string]",
    description: "Key-value pairs with string keys and string values",
    Java: "Map<String, String>",
    C: "NA",
    CPlusPlus: "std::map<std::string,std::string>",
    Python: "Dict[str, str]",
    JavaScript: "Object<string, string>",
    example_values: '{"key1": "value1", "key2": "value2"}',
  },
  {
    data_type: "matrix[int]",
    description: "2D array of integers",
    Java: "int[][]",
    C: "int[][]",
    CPlusPlus: "std::vector<std::vector<int>>",
    Python: "List[List[int]]",
    JavaScript: "Array<Array<number>>",
    example_values: "[[1, 2], [3, 4]]",
  },
  {
    data_type: "matrix[float]",
    description: "2D array of floating-point numbers",
    Java: "float[][]",
    C: "float[][]",
    CPlusPlus: "std::vector<std::vector<float>>",
    Python: "List[List[float]]",
    JavaScript: "Array<Array<number>>",
    example_values: "[[1.1, 2.2], [3.3, 4.4]]",
  },
];
