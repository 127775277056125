<template>
  <div>
    <b-modal
      id="createProblem-modal"
      class="modal_body-lg"
      centered
      title="Create Problem"
      scrollable
      no-close-on-backdrop
      @close="cancel"
      size="xl"
      hide-footer
    >
      <template #modal-title>
        <div class="">
          <div>Create Problem</div>
        </div>
      </template>
      <div class="modal_body-lg">
        <div v-if="loading" class="d-flex justify-content-center">
          <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
        </div>
        <b-form ref="form" class="form-section" @submit="handleSubmit">
          <div class="">
            <label class="p-2 text-secondary mb-0">Title</label>
            <div class="p-2">
              <b-form-input
                v-model="formDetails.Title"
                placeholder="Enter title for problem"
                required
              ></b-form-input>
            </div>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Publish Type</label>
            <b-form-group class="p-2">
              <b-button-group toggle class="d-flex">
                <b-button
                  v-for="(option, index) in publishOptions"
                  :key="index"
                  :variant="
                    formDetails.Publish_Type === option.value
                      ? 'primary'
                      : 'secondary'
                  "
                  @click="formDetails.Publish_Type = option.value"
                  class="d-flex align-items-center"
                  :active="formDetails.Publish_Type === option.value"
                >
                  <b-icon :icon="option.icon" class="mr-2"></b-icon>
                  {{ option.text }}
                  <b-icon
                    icon="info-circle"
                    class="ml-2 small-info-icon"
                    :title="option.info"
                  ></b-icon>
                </b-button>
              </b-button-group>
            </b-form-group>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Description</label>
            <div class="p-2">
              <b-form-textarea
                v-model="formDetails.Description"
                placeholder="Enter description for problem"
                rows="3"
                required
              ></b-form-textarea>
            </div>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Difficulty Level</label>
            <b-form-group class="p-2">
              <b-button-group toggle class="d-flex">
                <b-button
                  v-for="(option, index) in difficultyOptions"
                  :key="index"
                  :variant="
                    formDetails.Difficulty === option.value
                      ? 'primary'
                      : 'secondary'
                  "
                  @click="formDetails.Difficulty = option.value"
                  class="d-flex align-items-center"
                  :active="formDetails.Difficulty === option.value"
                >
                  <b-icon :icon="option.icon" class="mr-2"></b-icon>
                  {{ option.text }}
                  <b-icon
                    v-if="option.info"
                    icon="info-circle"
                    class="ml-2 small-info-icon"
                    :title="option.info"
                  ></b-icon>
                </b-button>
              </b-button-group>
            </b-form-group>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Tags</label>
            <div class="p-2">
              <tags-input
                class="tags-input form-control"
                v-model="tabVal"
                :tags="formDetails.Tags"
                placeholder="Search tags (or) Press space to view all tags"
                :add-only-from-autocomplete="true"
                :autocomplete-items="allTags"
                @tags-changed="handleTagsChanged"
              ></tags-input>
            </div>
          </div>

          <div class="">
            <label class="p-2 text-secondary mb-0">Select No. of Inputs</label>
            <div class="p-2">
              <b-form-input
                v-model="formDetails.inputCount"
                type="number"
                :min="1"
                required
                @input="updateInputCount"
              ></b-form-input>
            </div>
          </div>
          <div class="inputsData-container">
            <div
              class="inputsData-item"
              v-for="(item, index) in formDetails.inputData"
              :key="index"
            >
              <label class="p-2 text-secondary mb-0"
                >Enter label Name & select Datatype of input
                {{ index + 1 }}:</label
              >
              <div class="p-2 d-flex">
                <b-form-input
                  v-model="item.name"
                  @input="updateTestCases(item,'inputData',index)"
                  placeholder="Enter name for input "
                  required
                  :state="item.isValid ? true : false"
                ></b-form-input>
                <b-dropdown
                  :text="item.dataType"
                  variant="primary"
                  class=""
                  required
                  menu-class="c1-dropdown"
                >
                  <b-dropdown-item
                    v-for="option in datatypeOptions"
                    :key="option.value"
                    :id="'option-' + option.value"
                    @click="handleInputypeChange(index, option)"
                    v-b-tooltip.hover
                    :title="`${option.description} - Example: ${option.example_values}`"
                  >
                    {{ option.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <b-form-invalid-feedback :state="item.isValid ? true : false" class="ml-2">
                Should be a proper variable name.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Expected Output</label>
            <div class="p-2">
              <b-dropdown
                :text="formDetails.expectedOutput_DataType"
                class="w-half"
                required
                variant="outline-primary"
                block
                menu-class="w-100 text-center c1-dropdown"
              >
                <b-dropdown-item
                  v-for="option in datatypeOptions"
                  :key="option.value"
                  @click="handleOutputypeChange(option)"
                  :id="'option-' + option.value"
                  v-b-tooltip.hover
                  :title="`${option.description} - Example: ${option.example_values}`"
                >
                  {{ option.text }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="testcases-Container">
            <div><label class="p-2 mb-0 text-bold">Test Cases:</label></div>
            <div
              v-for="(testCase, index) in formDetails.TestCasesData"
              :key="index"
              class="tm-testcase-div mt-3 mb-4"
            >
              <div class="d-flex">
                <div class="w-full">
                  <label class="p-2 text-secondary mb-0"
                    >Test Case {{ index + 1 }} Name</label
                  >
                  <div class="p-2">
                    <b-form-input
                      v-model="testCase.name"
                      placeholder="Enter Test Case Name"
                      required
                    ></b-form-input>
                  </div>
                </div>

                <div>
                  <label class="p-2 text-secondary mb-0">Display</label>
                  <div class="p-2 text-center">
                    <b-form-checkbox
                      v-model="testCase.displayFlg"
                      value="Y"
                      unchecked-value="N"
                      required
                      size="lg"
                      v-b-tooltip.hover="
                        'Want to show this test case to participant'
                      "
                      data-placement="top"
                    />
                  </div>
                </div>
              </div>

              <div class="p-2 inputsData-container">
                <div
                  class="inputsData-item"
                  v-for="(item, index) in testCase.inputs"
                  :key="index"
                >
                  <label
                    class="p-2 text-secondary mb-0 d-flex justify-content-between"
                  >
                    <div>{{ item.label }}</div>
                    <div class="">Example value: {{ item.example }}</div>
                  </label>

                  <div class="p-2 d-flex">
                    <b-form-input
                      v-model="item.value"
                      placeholder="Enter Value for input "
                      required
                    ></b-form-input>
                  </div>
                 
                </div>
                <div class="inputsData-item">
                  <label
                    class="p-2 text-secondary mb-0 d-flex justify-content-between"
                  >
                    <div>Output</div>
                    <div class="">
                      Example value: {{ formDetails.expectedOutput_example }}
                    </div>
                  </label>
                  <div class="p-2 d-flex">
                    <b-form-input
                      v-model="testCase.expected_output"
                      placeholder="Enter Value for Output "
                      required
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-3">
            <button
              class="submitButton addButton"
              @click="addNewTestCase"
              type="button"
            >
              + Test Case
            </button>
          </div>
          <div class="d-flex justify-content-end border-top pt-3">
            <button class="submitButton finishButton" type="submit">
              Create Problem
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
      id="create-error-modal"
      ref="create-error-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title="Oops."
    >
      <p class="my-4">{{ errMsg }}. Please try again</p>
    </b-modal>
  </div>
</template>

<script>
import { createCodeProblem } from "../../api/index";
import TagsInput from "@johmun/vue-tags-input";
import { tags } from "../../constants/codeTags";
import { codeDatatypes } from "../../constants/codeDatatypes";

export default {
  name: "createProblemModal",
  components: { TagsInput },
  data() {
    return this.initialState();
  },
  computed: {
    computedTestCasesData() {
      return this.generateTestCases();
    },
  },
  watch: {
    // "formDetails.inputData": "updateTestCases",
    // "formDetails.expectedOutput_DataType": "updateTestCases",
  },

  methods: {
    initialState() {
      return {
        loading: false,
        submitLoading: false,
        errMsg: "",
        formDetails: {
          Title: "",
          Created_By: "",
          Description: "",
          Publish_Type: "public",
          Difficulty: "Medium",
          Tags: [],
          inputCount: 1,
          inputData: [{ id: 1, name: "Input1", dataType: "int", example: 5, isValid:true }],
          expectedOutput_DataType: "int",
          expectedOutput_example: 5,
          TestCasesData: [
            {
              name: "Example 1",
              inputs: [{ label: "Input1", type: "int", value: "", example: 5 }],
              expected_output: "",
              output_type: "int",
              displayFlg: "Y",
            },
          ],
        },
        tabVal: "",
        allTags: tags || [],
        datatypeOptions: this.getDatatypeOptions() || [],
        difficultyOptions: [
          { value: "Easy", text: "Easy", icon: "star", info: "" },
          { value: "Medium", text: "Medium", icon: "gear", info: "" },
          {
            value: "Hard",
            text: "Hard",
            icon: "exclamation-triangle",
            info: "",
          },
        ],
        publishOptions: [
          {
            value: "public",
            text: "Public",
            icon: "globe",
            info: "Visible to everyone.",
          },
          {
            value: "org",
            text: "My Org",
            icon: "people",
            info: "Visible to your organization.",
          },
          {
            value: "private",
            text: "Private",
            icon: "lock",
            info: "Visible only to you.",
          },
        ],
      };
    },
    handleTagsChanged(tags) {
      this.formDetails.Tags = tags.map((e) => {
        return { text: e.text };
      });
      this.tabVal = "";
    },
    updateInputCount() {
      const Count = parseInt(this.formDetails.inputCount) || 1;
      this.formDetails.inputData = Array.from({ length: Count }, (v, i) => ({
        id: i + 1,
        name: this.formDetails.inputData[i]?.name || `Input${i + 1}`,
        dataType: this.formDetails.inputData[i]?.dataType || "int",
        example:this.formDetails.inputData[i]?.example || 5,
        isValid:this.formDetails.inputData[i]?.isValid !== undefined ? this.formDetails.inputData[i].isValid : true,
      }));
      this.updateTestCases(); // Update TestCasesData when inputCount changes
    },

    updateTestCases(item,arr,index) {
      if(item){
        this.formDetails[arr][index].isValid=this.isValidInputLabel(item.name);
      }
      this.formDetails.TestCasesData = this.generateTestCases();
    },
    cancel() {
      Object.assign(this.$data, this.initialState());
      this.$bvModal.hide("createProblem-modal");
    },

    getDatatypeOptions() {
      // Extract the unique data types from codeDatatypes
      const codeDatas = codeDatatypes;

      // Map the data_type values to the options format
      return codeDatas.map((item) => ({
        text: item.data_type,
        value: item.data_type,
        description: item.description,
        example_values: item.example_values,
      }));
    },

    generateTestCases() {
      const inputData = this.formDetails?.inputData || [];
      const testCaseData = this.formDetails?.TestCasesData || [];
      const expectedOutput_DataType =
        this.formDetails?.expectedOutput_DataType || "";

      return Array.from({ length: testCaseData.length }, (v, i) => ({
        name: testCaseData[i]?.name || "",
        inputs: inputData.map((input, index) => ({
          label: input.name,
          type: input.dataType,
          value: testCaseData[i]?.inputs[index]?.value || "",
          example: input.example,
        })),
        expected_output: testCaseData[i]?.expected_output || "",
        output_type: expectedOutput_DataType,
        displayFlg: testCaseData[i]?.displayFlg || "Y",
      }));
    },
    addNewTestCase() {
      const inputData = this.formDetails?.inputData || [];
      const testCaseData = this.formDetails?.TestCasesData || [];
      const expectedOutput_DataType =
        this.formDetails?.expectedOutput_DataType || "";

      const newTCobj = {
        name: `Example ${testCaseData.length + 1}`,
        inputs: inputData.map((input) => ({
          label: input.name,
          type: input.dataType,
          value: "",
          example: input.example,
        })),
        expected_output: "",
        output_type: expectedOutput_DataType,
        displayFlg: "Y",
      };
      this.formDetails?.TestCasesData.push(newTCobj);
    },
    handleSubmit(event) {
      event.preventDefault();
      this.submitLoading = true;
      // Check if all inputs are valid before submitting
      const allValid = this.formDetails.inputData.every(item => item.isValid);
        if (!allValid) {
          this.submitLoading = false;
          this.errMsg = 'Please fix the errors before submitting.';
          this.$bvModal.show("create-error-modal");
          return;
        }

      const tags = this.formDetails.Tags.map((e) => {
        return e.text;
      });

      let payload = {
        Title: this.formDetails.Title,
        Description: this.formDetails.Description,
        Tags: JSON.stringify(tags),
        Difficulty: this.formDetails.Difficulty,
        Publish_Type: this.formDetails.Publish_Type,
        TestCases: JSON.stringify(this.formDetails.TestCasesData),
      };
      createCodeProblem(payload)
        .then((resp) => {
          console.log("resp.data.data--,",resp.data)
          this.submitLoading = false;
          this.cancel();
          this.$emit("refreshProbelmsList");
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.submitLoading = false;
          this.errMsg = err.response.data;
          this.$bvModal.show("create-error-modal");
        });
    },
    handleOutputypeChange(val) {
      this.formDetails.expectedOutput_DataType = val.value;
      this.formDetails.expectedOutput_example = val.example_values;
      this.updateTestCases();
    },
    handleInputypeChange(index, val) {
      this.formDetails.inputData[index].dataType = val.value;
      this.formDetails.inputData[index].example = val.example_values;
      this.updateTestCases();
    },
    isValidInputLabel(value) {
      const regex = /^[A-Za-z][A-Za-z0-9]*$/;
      return regex.test(value);
    },
  },
};
</script>

<style>
.w-half {
  width: 50% !important;
}
.small-info-icon {
  font-size: 0.75rem !important; /* Adjust the size of the icon */
  cursor: pointer;
  margin-left: auto !important;
}
.tags-input {
  max-width: 100% !important;
}
.ti-input {
  border: none !important;
  padding: 0px !important;
}
.ti-autocomplete {
  max-height: 200px !important;
  overflow: auto !important;
  width: 99% !important;
}
.ti-tag {
  background: var(--primary-color) !important;
  padding: 1px 8px !important;
  margin: 0px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.ti-new-tag-input,
.ti-tag-center {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.ti-new-tag-input-wrapper {
  padding: 0px !important;
  padding-left: 4px !important;
  margin: 0px !important;
}
.inputsData-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next row */
  gap: 16px;
}
.inputsData-item {
  flex: 0 1 calc(50% - 16px); /* Each item takes up approximately 50% of the container's width minus the gap */
  box-sizing: border-box;
}
.tm-testcase-div {
  background-color: var(--light-gray);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.c1-dropdown {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
