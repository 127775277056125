var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container"},[_vm._m(0),_c('div',{staticClass:"codeProblem-list"},_vm._l((_vm.interviewResultsData),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleResultClick(item)}}},[_c('div',{staticClass:"codeProblem-item-row"},[_c('div',{staticClass:"flex-3",attrs:{"title":item.FullName}},[_vm._v(" "+_vm._s(item.FullName)+" ")]),_c('div',{staticClass:"flex-3"},[_vm._v(_vm._s(item.Email))]),_c('div',{staticClass:"flex-3"},[_vm._v(_vm._s(item.Status))]),_c('div',{staticClass:"flex-3 text-bold text-md",class:{
            'difficulty-easy': item.AverageScore >= 70,
            'difficulty-medium':
              item.AverageScore >= 45 && item.AverageScore < 70,
            'difficulty-hard': item.AverageScore < 45,
          }},[_vm._v(" "+_vm._s(item.AverageScore)+" ")])]),(_vm.selectedResultsData.CandidateId == item.CandidateId)?_c('b-collapse',{staticClass:"m-3 background-light-gray",attrs:{"id":"collapse-4"},model:{value:(_vm.showDetailedResult),callback:function ($$v) {_vm.showDetailedResult=$$v},expression:"showDetailedResult"}},_vm._l((_vm.selectedResultsData.Problems),function(item,index){return _c('div',{key:index,staticClass:"codeProblem-item-row border-0"},[_c('div',{staticClass:"flex-3",attrs:{"title":item.Title}},[_vm._v(" "+_vm._s(item.Title)+" ")]),_c('div',{staticClass:"flex-3"},[_vm._v(_vm._s(item.Difficulty))]),_c('div',{staticClass:"flex-3"},[_vm._v(_vm._s(item.Status))]),_c('div',{staticClass:"flex-3 text-bold text-md",class:{
            'difficulty-easy': item.Score >= 70,
            'difficulty-medium': item.Score >= 45 && item.Score < 70,
            'difficulty-hard': item.Score < 45,
          }},[_vm._v(" "+_vm._s(item.Score)+" ")])])}),0):_vm._e()],1)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeProblem-list-header"},[_c('div',{staticClass:"flex-3"},[_vm._v("Name")]),_c('div',{staticClass:"flex-3"},[_vm._v("Email")]),_c('div',{staticClass:"flex-3"},[_vm._v("Status")]),_c('div',{staticClass:"flex-3"},[_vm._v("Score")])])
}]

export { render, staticRenderFns }