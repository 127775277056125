// Axios
import axios from "axios";
import store from "../store";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
const service = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
});

// request interceptor
service.interceptors.request.use(
    (config) => {


        // do something before request is sent

        if (store.state.candidateInfo.candidateFlg) {
            config.headers["Authorization"] = store.state.candidateInfo.token;
        }
        else if(store.state.accessToken) {
            config.headers["Authorization"] = store.state.accessToken;
        }
        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    }
);


// response interceptor
service.interceptors.response.use(response => {
    return response;
}, async error => {

    // debugger
    if (error.response) {
        if (error.response.status === 401) {
            console.log(error.response.data);
            window.location.href = "/";
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
});

export const CreateUser = (data) => {
    return service({
        url: "/register",
        method: "post",
        data,
    });
};
export const Login = (data) => {
    return service({
        url: "/login",
        method: "post",
        data,
    });
};
export const resetPassword = (data) => {
    return service({
        url: "/resetpassword",
        method: "post",
        data,
    });
};
export const sendResetPassEmail = (data) => {
    return service({
        url: "/sendresetpassemail",
        method: "post",
        data,
    });
};
export const demoRequest = (data) => {
    return service({
        url: "/demorequest",
        method: "post",
        data,
    });
};

export const getUserList = () => {
    return service({
        url: "/getuserdetails",
        method: "get",
    });
};
export const getEventDetails = () => {
    return service({
        url: "/geteventdetails",
        method: "get",
    });
};
export const verifyAccount = (params) => {
    return service({
        url: "/verify",
        method: "get",
        params
    });
};

export const feedbackDetails = (data) => {
    return service({
        url: "/feedbackdetails",
        method: "post",
        data,
    });
};
export const createMPEventDataset = (data) => {
    return service({
        url: "/creatempeventdataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const createDataset = (data) => {
    return service({
        baseURL: process.env.VUE_APP_DATASET_API_ENDPOINT,
        url: "/dataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const getDatasetbyId = (data_code) => {
    return service({
        baseURL: process.env.VUE_APP_DATASET_API_ENDPOINT,
        url: `/dataset/${data_code}`,
        method: "get",
        
    });
};
export const createFFFEventDataset = (data) => {
    return service({
        url: "/createfffeventdataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const createScblEventDataset = (data) => {
    return service({
        url: "/createscbleventdataset",
        method: "post",
        data,
        headers: { "Content-Type": "multipart/form-data" },
    });
};
export const getRoomData = (data_code) => {
    return service({
        url: `/join/${data_code}`,
        method: "get",
    });
};

export const getProblemDetails = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/codeProblemsRoutes/getProblem`,
        method: "get",
        params:payload
    });
};
export const getCandidateProblemDetails = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/codeProblemsRoutes/getCandidateProblem`,
        method: "get",
        params:payload
    });
};
export const createCodeProblem = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/codeProblemsRoutes/createCodeProblem`,
        method: "post",
        data:payload
    });
};
export const codeCompileanRrun = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/compilerRouter/compileandrun`,
        method: "post",
        data:payload
    });
};
export const getAllCodeProblems = () => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/codeProblemsRoutes/getAllCodeProblems`,
        method: "get"
    });
};
export const getCodeProblems = () => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/codeProblemsRoutes/getCodeProblems`,
        method: "get"
    });
};
export const getAllInterviews = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/getAllInterviews`,
        method: "get",
        params:payload
    });
};
export const getInterviewDetails = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/getInterviewDetails`,
        method: "get",
        params:payload
    });
};

export const addInterviewProblem = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/insertInterviewProblemAssociation`,
        method: "post",
        data:payload
    });
};

export const createInterview = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/createInterview`,
        method: "post",
        data:payload
    });
};

export const getInterviewBasicData = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/candidatejoinRoutes/getInterviewBasicDetails`,
        method: "get",
        params:payload
    });
};
export const createCandidateRegistration = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/candidatejoinRoutes/registerCandidate`,
        method: "post",
        data:payload
    });
};
export const getCandidateInterviewDetails = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/getCandidateInterview`,
        method: "get",
        params:payload
    });
};

export const submitCodeProblem = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/submitCodeProblem`,
        method: "post",
        data:payload
    });
};
export const getInterviewResults = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/getInterviewResults`,
        method: "get",
        params:payload
    });
};
export const updateInterview = (payload) => {
    return service({
        baseURL: process.env.VUE_APP_TM_INTERVIEW_API_ENDPOINT,
        url: `/interviewRoutes/updateInterviewDetails`,
        method: "post",
        data:payload
    });
};