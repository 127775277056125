<template>
  <div id="app">
    <Sidebar v-if="showSidebar" />
    <div :class="showSidebar ? 'content' : 'content-full'">
      <Header v-if="showSidebar" />
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar/sidebar.vue";
import Header from "./components/Header/Header.vue";

export default {
  metaInfo: {
    title: "TeamsMagic: #1 Employee engagement and technical assessment platform",
    meta: [
      {
        name: "description",
        content:
          "Elevate your team's productivity with TeamsMagic! Discover seamless collaboration and communication tools that empower your workforce.",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
    ],
    script: [
      {
        type: "application/ld+json",
        json: {
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "TeamsMagic",
          url: "https://www.teamsmagic.com",
          logo: "https://teamsmagicdevstorage.blob.core.windows.net/frontend-images/TM_logo.png",
          sameAs: ["https://www.linkedin.com/company/teamsmagic"],
          contactPoint: [
            {
              "@type": "ContactPoint",
              telephone: "+91-94892-10341",
              contactType: "Office",
            },
          ],
        },
      },
    ],
  },
  components: {
    Sidebar,
    Header,
  },
  data() {
    return {
      // Initialize user details with null or default values
      userDetails: null,
    };
  },
  computed: {
    showSidebar() {
      const { requireAuth, showSidebar } = this.$route.meta;
      if (typeof showSidebar === "boolean") {
        return requireAuth && showSidebar;
      }
      return requireAuth;
    },
  },
  mounted() {
    this.initializeTeamsSdk();
  },
  methods: {
    initializeTeamsSdk() {
      // Check if running inside Microsoft Teams
      if (window.microsoftTeams) {
        window.microsoftTeams.initialize();

        window.microsoftTeams.getContext((context) => {
          console.log("MS TEAMS FROM APP VUE", context);
          // Store user details from context
          this.userDetails = {
            userName: context.userPrincipalName,
            userId: context.userObjectId,
            // Add more details as needed
          };
          // You can now use userDetails in your component
        });
      } else {
        console.log("This app is not running inside Microsoft Teams.");
      }
    },
  },
};
</script>

<style>
#app {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular);
}
.content {
  margin-left: 250px;
  display: flex;
  min-height: 100vh; /* Ensures the content container takes up the full viewport height */
  flex-direction: column; /* Aligns the content vertically */
}

html,
body,
#fullheight {
  min-height: 100% !important;
  height: 100%;
  min-width: 100% !important;
  width: 100%;
}
.text-bold {
  font-weight: var(--font-weight-bold);
}
.color-primary {
  color: var(--primary-color);
}
.text-xl {
  font-size: 36px !important;
}
.text-2xl {
  font-size: var(--text-2xl) !important;
}
.text-md {
  font-size: var(--text-md) !important;
}
.text-lg {
  font-size: 20px;
}
.text-sm {
  font-size: 12px !important;
}

.border-radius-md{
  border-radius: var(--border-radius-md);
}



.background-primary{
  background: var(--primary-color) !important;
}
.background-secondary{
  background: var(--secondary-color) !important;
}
.background-light-gray{
  background: var(--light-gray) !important;
}
:root {
  --primary-color: #390879;
  --secondary-color: #b8df10;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --light-gray: #f8f8f8;
  /* --font-family: 'Press Start 2P', cursive;
  --button-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  --button-hover-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  --container-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  --border-radius: 8px; */

  --font-primary: "Montserrat", sans-serif;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --text-sm:12px;
  --text-md:16px;
  --text-lg:20px;
  --text-2lg:24px;
  --text-xl:36px;
  --text-2xl:48px;
  --text-3xl:52px;

  --border-radius-md:12px;
}
</style>
